import React, { useState, useRef, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Column, Row } from 'components/Layout'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import Card from 'components/Card'
import Masonry from 'components/Masonry'
import Spacer from 'components/Spacer'
import IdeaCard from 'components/IdeaCard'
import * as IdeaCardComps from 'components/IdeaCard'
import NotLoggedIn from 'components/NotLoggedIn'
import Loader from 'components/Loader'
import Select from 'components/Input/Select'

import * as ideasActions from 'reducers/actions/ideas'
import * as logsActions from 'reducers/actions/logs'
import { shadeColor } from 'helpers/colors'
import styles from './Ideas.module.css'

const AddIdeaContent = ({ small }) => {
  const { t } = useTranslation()
  if (small) {
    return null
  } else {
    return (
      <Fragment>
        <Spacer size={3} />
        <span className="text-ellipsis">{t('Let’s add a new idea!')}</span>
      </Fragment>
    )
  }
}

const AddIdea = ({ small, category, organization, ...props }) => {
  const classesList = [small ? 'small' : 'active', props.className]
  const className = classesList.filter(name => Boolean(name)).join(' ')
  const to = { pathname: '/ideas/add', state: category.id }
  const dispatch = useDispatch()
  const linkClass = `btn ${className} nowrap overflow-hidden`
  const onClick = () => {
    const options = {
      action: '[Ideas]:GO_TO_NEW_IDEA',
      category: category.id,
      oid: organization.id,
    }
    dispatch(logsActions.add(options))
  }
  return (
    <Link className={linkClass} to={to} onClick={onClick}>
      <i className="fas fa-plus" />
      <AddIdeaContent small={small} />
    </Link>
  )
}

const sortByDate = (first, second) => {
  const firstTime = first.createdAt ? first.createdAt.toMillis() : Date.now()
  const secondTime = second.createdAt ? second.createdAt.toMillis() : Date.now()
  if (firstTime < secondTime) {
    return 1
  } else if (firstTime === secondTime) {
    return 0
  } else {
    return -1
  }
}

const sortByVotes = (first, second) => {
  if (first.votes < second.votes) {
    return 1
  } else if (first.votes === second.votes) {
    return sortByDate(first, second)
  } else {
    return -1
  }
}

const filterAccordingToCategory = (ideas, filter) => {
  return ideas.filter(idea => {
    return !filter || filter.id === 'all' || idea.category === filter.id
  })
}

const selectSorter = sortBy => (first, second) => {
  if (sortBy === 'date') {
    return sortByDate(first, second)
  } else {
    return sortByVotes(first, second)
  }
}

const FilteredIdeas = ({ ideas, filter, organization, editable, sortBy }) => (
  <Masonry
    items={filterAccordingToCategory(ideas, filter).sort(selectSorter(sortBy))}
    strategy="autofill"
    columnsWidth={300}
    gap={24}
    render={({ idea, width }) => (
      <IdeaCard
        editable={editable}
        idea={idea}
        categories={organization.categories || []}
        organization={organization}
        key={idea.id}
        preview
        width={width}
      />
    )}
  />
)

const FilterButton = ({ organization, filter, setFilter, category }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isActive = filter && filter.name === category.name
  const onClick = () => {
    const options = {
      action: '[Ideas]:SET_FILTER',
      cid: category.id,
      name: category.name,
      oid: organization.id,
    }
    dispatch(logsActions.add(options))
    setFilter(category)
  }
  const klass = isActive ? styles.activeCategoryButton : styles.categoryButton
  const categoryClassName = [klass].filter(name => Boolean(name)).join(' ')
  const colorBoxClassName = styles.colorBox
  const lighten = shadeColor(category.color, 40)
  const background = `linear-gradient(to right, ${category.color}, ${lighten})`
  return (
    <div className={styles.categoryButtonSpacer}>
      <Row
        tag="button"
        align="center"
        justify="center"
        className={categoryClassName}
        onClick={onClick}
      >
        <div className={colorBoxClassName} style={{ background }} />
        <span className={styles.colorBoxTitle}>
          {category.name === 'To categorize' ? t(category.name) : category.name}
        </span>
      </Row>
    </div>
  )
}

const RenderFilterButtons = ({ organization, ...props }) => {
  const { t } = useTranslation()
  const categories = organization.categories || []
  const all = { name: t('All'), color: 'var(--purple)', id: 'all' }
  const categoriesAndAll = [all, ...categories]
  return (
    <Row className={styles.banner} align="center">
      {categoriesAndAll.map(category => (
        <FilterButton
          category={category}
          key={category.name}
          organization={organization}
          {...props}
        />
      ))}
    </Row>
  )
}

const NoIdeasContent = ({ organization, ideaState, filter }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const wait = 'You should wait for your organization to add you directly'
  const onClick = () => {
    const options = { action: '[Ideas]:GO_TO_ORGANIZATION_NEW' }
    dispatch(logsActions.add(options))
  }
  if (Object.keys(organization).length === 0) {
    return (
      <Fragment>
        <div className={styles.nothingHereLite}>{t(wait)}</div>
        <Spacer size={12} />
        <Link onClick={onClick} to="/organization/new" className="btn active">
          {t('Create a new organization')}
        </Link>
      </Fragment>
    )
  } else if (ideaState === 'current') {
    return <AddIdea organization={organization} category={filter} />
  } else {
    return (
      <div className={styles.nothingHereLite}>
        {t('Oops, looks like we’ll elected archived!')}
      </div>
    )
  }
}

const RenderNoIdeas = ({ filter, ideaState, organization }) => {
  const { t } = useTranslation()
  const oops = 'Oops, looks like there’s nothing here…'
  return (
    <Column grow align="center" justify="center" className={styles.width}>
      <div className={styles.nothingHere}>{t(oops)}</div>
      <Spacer size={12} />
      <NoIdeasContent
        organization={organization}
        ideaState={ideaState}
        filter={filter}
      />
    </Column>
  )
}

const ActualizeIdeasButton = ({ organization, toDisplay, filter }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const count = filterAccordingToCategory(toDisplay, filter).length
  const pad = { padding: '0 24px' }
  const onClick = () => {
    const options = {
      action: '[Ideas]:ACTUALIZE_IDEAS',
      oid: organization.id,
      cid: filter.id,
    }
    dispatch(logsActions.add(options))
    dispatch(ideasActions.actualizeIdeas)
  }
  return (
    <Row
      shrink
      align="center"
      overflow="hidden"
      className={styles.flex}
      style={pad}
    >
      {count === 0 ? null : (
        <Row
          tag="button"
          shrink
          justify="start"
          overflow="hidden"
          className="btn neutral"
          style={{ whiteSpace: 'nowrap' }}
          onClick={onClick}
        >
          <i className="fas fa-sync-alt" />
          <Spacer size={6} />
          <span className="text-ellipsis">
            {t('new ideas to display', { count })}
          </span>
        </Row>
      )}
    </Row>
  )
}

const SortOptions = props => {
  const { sortBy, setSortBy, organization, filter, ideaState } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const options = [
    { name: t('Date'), id: 'date' },
    { name: t('Votes'), id: 'votes' },
  ]
  const onChange = event => {
    const options = {
      action: '[Ideas]:SORT_IDEAS',
      oid: organization.id,
      displayed: filter.id,
      state: ideaState,
    }
    dispatch(logsActions.add(options))
    setSortBy(event.target.value)
  }
  const className = styles.sort
  return (
    <Row align="center" justify="end" className={className}>
      <div className={styles.sortName}>{t('Sort By')}</div>
      <Select options={options} onChange={onChange} selected={sortBy} />
    </Row>
  )
}

const IdeasOptions = props => {
  const { organization, filter } = props
  const hidden = 'hidden-phone'
  return (
    <Row justify="center" className={styles.addDesktop}>
      <ActualizeIdeasButton {...props} />
      <Row align="center" overflow="hidden" shrink grow className={hidden}>
        <AddIdea
          organization={organization}
          category={filter}
          className={styles.addCenter}
        />
      </Row>
      <SortOptions {...props} />
    </Row>
  )
}

const MobileButtons = ({ organization, filter }) => (
  <Fragment>
    <Spacer size={40} className="visible-phone" />
    <AddIdea
      small
      organization={organization}
      category={filter}
      className={styles.addPlus}
    />
  </Fragment>
)

const RenderIdeas = props => {
  const { allIdeas, ideaState, filter, organization, toDisplay } = props
  const ideas = allIdeas[ideaState]
  const [sortBy, setSortBy] = useState('date')
  if (organization === 'loading' || ideas === 'loading') {
    return (
      <Column grow align="center" justify="center">
        <Loader />
      </Column>
    )
  } else if (ideas.length === 0) {
    return (
      <RenderNoIdeas
        ideaState={ideaState}
        organization={organization}
        filter={filter}
      />
    )
  } else {
    return (
      <Fragment>
        <IdeasOptions
          toDisplay={toDisplay}
          filter={filter}
          sortBy={sortBy}
          setSortBy={setSortBy}
          organization={organization}
          ideaState={ideaState}
        />
        <FilteredIdeas
          organization={organization}
          ideas={ideas}
          filter={filter}
          editable={ideaState === 'current'}
          sortBy={sortBy}
        />
        <MobileButtons organization={organization} filter={filter} />
      </Fragment>
    )
  }
}

const IdeaSelectorButton = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id, name, ideaState, setIdeaState, setFilter } = props
  const classButton =
    ideaState === id ? styles.activeCategoryButton : styles.categoryButton
  const onClick = () => {
    dispatch(logsActions.add({ action: '[Ideas]:SET_IDEAS_STATE', state: id }))
    setIdeaState(id)
    setFilter({ name: t('All'), id: 'all' })
  }
  return (
    <div className={styles.ideaStateSpacer}>
      <button className={classButton} onClick={onClick}>
        <div style={{ margin: 0 }} className={styles.colorBoxTitle}>
          {t(name)}
        </div>
      </button>
    </div>
  )
}

const RenderIdeaStateSelector = props => {
  const states = [
    { id: 'current', name: 'Current' },
    { id: 'archived', name: 'Archived' },
    { id: 'realizing', name: 'Realizing' },
  ]
  return (
    <Row className={styles.ideaStateSelector}>
      {states.map(state => (
        <IdeaSelectorButton {...state} {...props} key={state.id} />
      ))}
    </Row>
  )
}

const filterIdeas = selectedOrganization => ideas => {
  if (ideas === 'loading') {
    return ideas
  } else if (ideas[selectedOrganization] === 'loading') {
    return 'loading'
  } else if (!ideas[selectedOrganization]) {
    return []
  } else {
    return ideas[selectedOrganization].filter(
      i => i.oid === selectedOrganization
    )
  }
}

const useFixBody = () => {
  const ref = useRef(null)
  useEffect(() => {
    document.body.style.setProperty('overflow', 'hidden')
    const node = ref.current
    const iOS =
      navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    if (node && iOS) disableBodyScroll(node)
    return () => {
      if (iOS) enableBodyScroll(node)
      document.body.style.removeProperty('overflow')
    }
  }, [ref])
  return ref
}

const LoaderOrNotFound = ({ close, loading }) => {
  const { t } = useTranslation()
  if (loading) {
    return <Loader />
  } else {
    const idea = { title: t('404 - Idea not found!') }
    return (
      <Fragment>
        <IdeaCardComps.Body>
          <IdeaCardComps.Title idea={idea} close={close} />
          <Spacer size={6} />
          {t('It looks like this idea does not exist. Try another one.')}
          <Spacer size={12} />
          <button className="btn active" onClick={close}>
            {t('Close this window')}
          </button>
        </IdeaCardComps.Body>
        <Spacer size={12} />
      </Fragment>
    )
  }
}

const OverIdea = ({ idea, editable, organization, loading }) => {
  const ref = useFixBody()
  const dispatch = useDispatch()
  const history = useHistory()
  const closeOverlay = onOverlay => event => {
    if (onOverlay) {
      if (event.target === ref.current) {
        const iid = idea.id
        const action = '[Ideas]:CLOSE_OVERIDEA_OVERLAY_OVERLAY'
        const options = { action, iid }
        dispatch(logsActions.add(options))
        history.push('/ideas/all')
      }
    } else {
      const iid = idea.id
      const options = { action: '[Ideas]:CLOSE_OVERIDEA_OVERLAY_CROSS', iid }
      dispatch(logsActions.add(options))
      history.push('/ideas/all')
    }
  }
  const columnStyle = {
    margin: 'auto',
    maxWidth: 600,
    width: 'calc(100vw - 48px)',
  }
  const align = loading ? 'center' : null
  const [height, setHeight] = useState(window.innerHeight)
  useEffect(() => {
    const updater = () => setHeight(window.innerHeight)
    window.addEventListener('resize', updater)
    return () => window.removeEventListener('resize', updater)
  }, [])
  return (
    <div
      ref={ref}
      className={styles.overlayIdea}
      style={{ height }}
      onClick={closeOverlay(true)}
    >
      <Column grow style={columnStyle} align={align}>
        {idea ? (
          <IdeaCard
            editable={editable}
            idea={idea}
            categories={organization.categories || []}
            organization={organization}
            key={idea.id}
            close={closeOverlay(false)}
          />
        ) : (
          <Card pad={loading}>
            <LoaderOrNotFound close={closeOverlay(false)} loading={loading} />
          </Card>
        )}
      </Column>
    </div>
  )
}

const getIdeaToDisplay = (ideas, iid) => {
  const reducer = (acc, [state, values]) => {
    if (acc) {
      return acc
    } else if (values !== 'loading') {
      const idea = values.find(i => i.id === iid)
      return idea ? [state, idea] : null
    } else {
      return null
    }
  }
  const val = Object.entries(ideas).reduce(reducer, null)
  return val || [null, null]
}

const Ideas = () => {
  const { t } = useTranslation()
  const { user, organization, ideas, toDisplay } = useSelector(
    ({ user, organizations, selectedOrganization, ...state }) => {
      const bySelected = org => org.id === selectedOrganization
      const organization =
        organizations === 'loading'
          ? 'loading'
          : Object.values(organizations).find(bySelected) || {}
      const filter = filterIdeas(selectedOrganization)
      const current = filter(state.currentIdeas)
      const archived = filter(state.archivedIdeas)
      const realizing = filter(state.realizingIdeas)
      const ideas = { current, archived, realizing }
      return { user, organization, ideas, toDisplay: state.ideasToDisplay }
    }
  )
  const params = useParams()
  const [filter, setFilter] = useState({ name: t('All'), id: 'all' })
  const [ideaState, setIdeaState] = useState('current')
  const [state, ideaToDisplay] = getIdeaToDisplay(ideas, params.id)
  if (user) {
    const reducer = (acc, val) => acc || val === 'loading'
    const loading = Object.values(ideas).reduce(reducer, false)
    return (
      <Fragment>
        {Boolean(params.id) && (
          <OverIdea
            loading={loading}
            idea={ideaToDisplay}
            editable={state === 'current'}
            organization={organization}
          />
        )}
        {organization === 'loading' ||
        (Object.keys(organization).length === 0 &&
          ideas[ideaState] !== 'loading') ? null : (
          <Fragment>
            <RenderIdeaStateSelector
              ideaState={ideaState}
              setIdeaState={setIdeaState}
              setFilter={setFilter}
            />
            <RenderFilterButtons
              filter={filter}
              setFilter={setFilter}
              organization={organization}
            />
          </Fragment>
        )}
        <RenderIdeas
          allIdeas={ideas}
          ideaState={ideaState}
          filter={filter}
          organization={organization}
          toDisplay={toDisplay}
        />
      </Fragment>
    )
  } else {
    return <NotLoggedIn />
  }
}

export default Ideas
