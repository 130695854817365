import * as usersServices from 'services/users'
import * as logsActions from 'reducers/actions/logs'

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS'

const updateSettings = settings => ({
  type: UPDATE_USER_SETTINGS,
  settings,
})

const updateSlackSettings = params => async (dispatch, getState) => {
  const { settings, user } = getState()
  const { ideasCreated, ideasParticipation } = settings.slack || {
    ideasCreated: false,
    ideasParticipation: false,
  }
  try {
    const slack = {
      ideasCreated: params.ideasCreated ? !ideasCreated : ideasCreated,
      ideasParticipation: params.ideasParticipation
        ? !ideasParticipation
        : ideasParticipation,
    }
    dispatch(updateSettings({ ...settings, slack }))
    const { uid } = user
    const sid = 'slack'
    await usersServices.setSettings({ uid, sid, content: slack })
    const sett = await usersServices.getSettings(uid)
    dispatch(updateSettings(sett))
  } catch (error) {
    dispatch(logsActions.crash.add(error))
    const slack = { ideasCreated, ideasParticipation }
    dispatch(updateSettings({ ...settings, slack }))
  }
}

export { updateSettings, updateSlackSettings }
