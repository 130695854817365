import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Title from 'components/Title'
import Spacer from 'components/Spacer'
import { Column, Row } from 'components/Layout'
import Input from 'components/Input/Text'
import Submit from 'components/Input/Submit'
import Callout from 'components/Callout'
import Loader from 'components/Loader'
import NotLoggedIn from 'components/NotLoggedIn'
import Card from 'components/Card'

import * as ideas from 'reducers/actions/ideas'
import * as organization from 'reducers/actions/organization'
import * as logsActions from 'reducers/actions/logs'
import * as auth from 'reducers/actions/auth'
import styles from './New.module.css'

const onSubmit = ({
  dispatch,
  name,
  domain,
  setLoading,
  setError,
  history,
}) => async event => {
  try {
    setError(null)
    setLoading(true)
    event.preventDefault()
    const action = '[Organization/New]:CREATE_ORGANIZATION'
    const options = { action, name, domain }
    dispatch(logsActions.add(options))
    const org = await dispatch(
      organization.createOrganization({ name, domain })
    )
    await dispatch(auth.refreshClaims)
    await dispatch(organization.refreshOrganizations)
    await dispatch(ideas.getOrgIdeas(org))
    setLoading(false)
    history.push('/')
  } catch (error) {
    dispatch(logsActions.crash.add(error))
    setLoading(false)
    setError(error.message)
  }
}

const New = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [domain, setDomain] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const history = useHistory()
  const user = useSelector(({ user }) => user)
  const enabled = name !== '' && domain.match(/^[\w][-\w]{0,62}(\.\w+)+$/g)
  const onSubmit_ = onSubmit({
    dispatch,
    name,
    domain,
    setLoading,
    setError,
    history,
  })
  if (!!user && user !== 'loading') {
    return (
      <Column align="center">
        <div className={styles.wrapper}>
          <Title>{t('New organization')}</Title>
          <Spacer size={6} />
          <Card tag="form" pad onSubmit={onSubmit_}>
            {loading ? (
              <Row
                className={styles.loaderCenter}
                align="center"
                justify="center"
              >
                <Loader />
              </Row>
            ) : null}
            <Column
              style={{ opacity: loading ? 0 : 1, transition: 'opacity .2s' }}
            >
              {error ? <Callout error>{t(error)}</Callout> : null}
              <Input
                label={t('Organization name')}
                type="text"
                value={name}
                onChange={event => setName(event.target.value)}
                placeholder="Stark Industries"
              />
              <Callout info>
                {t(
                  'This will be the public name. You can always change it later.'
                )}
              </Callout>
              <Input
                label={t('Organization domain name')}
                type="text"
                value={domain}
                onChange={event => setDomain(event.target.value.toLowerCase())}
                placeholder="stark-industries.com"
              />
              <Callout info>
                {t(
                  'Be careful, all people with an email address ending with your domain will automatically be added to your organization.'
                )}
              </Callout>
              <Submit disabled={!enabled} marginTop />
            </Column>
          </Card>
        </div>
      </Column>
    )
  } else {
    return <NotLoggedIn />
  }
}

export default New
