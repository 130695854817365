import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Card from 'components/Card'
import Spacer from 'components/Spacer'
import {
  Title,
  Content,
  ColorStripe,
  Body,
  Interactions,
} from 'components/IdeaCard'
import styles from './Home.module.css'

const DemoCard = ({ children, color, ...props }) => {
  const className = ['demo-card', props.className]
    .filter(t => !!t && t !== '')
    .join(' ')
  return (
    <Card className={className}>
      <ColorStripe color={color} />
      <Body>{children}</Body>
      <Spacer size={12} />
    </Card>
  )
}

const SectionText = ({ title, children }) => (
  <div>
    <h1 className={styles.header}>{title}</h1>
    <p className={styles.paragraph}>{children}</p>
  </div>
)

const FirstDemoCard = () => {
  const { t } = useTranslation()
  return (
    <DemoCard color="#FF0000">
      <Title
        idea={{ title: t('My head is a strainer') }}
        organization={{ name: 'Stark Industries' }}
      />
      <Content>
        {t('Hey guys,')}
        <br />
        <br />
        {t(
          'I just keep forgetting all my brilliant ideas. It’s getting really frustrating.'
        )}
        <br />
        {t('What if there was a way for me to keep them secure?')}
      </Content>
    </DemoCard>
  )
}
const SecondDemoCard = () => {
  const { t } = useTranslation()
  return (
    <DemoCard className={styles.reverseCols} color="#00FF00">
      <Title
        idea={{ title: t('This will surely get upvoted to oblivion!') }}
        organization={{ name: 'Stark Industries' }}
      />
      <Content>
        {t('I don’t know why.')}
        <br />
        {t('I just get the feeling that this is my lucky day.')}
      </Content>
      <Interactions
        idea={{
          createdAt: { toDate: () => new Date() },
          uid: '',
          commentsLength: 23,
        }}
        user={{ id: '' }}
        votes={{ length: 6347 }}
      />
    </DemoCard>
  )
}

const ThirdDemoCard = () => {
  const { t } = useTranslation()
  return (
    <DemoCard color="#0000FF">
      <div className="side-stripe purchase" />
      <div className="idea-text">
        <Title
          idea={{ title: t('Buying a couch') }}
          organization={{ name: 'Stark Industries' }}
        />
        <Content>
          {t('Hey guys,')}
          <br />
          <br />
          {t(
            'I noticed that the office looked cruelly empty I saw this used couch online and thought it might liven the space a fait bit.'
          )}
          <br />
          {t('Should I buy it?')}
        </Content>
      </div>
    </DemoCard>
  )
}

const Home = () => {
  const { t } = useTranslation()
  const user = useSelector(({ user }) => user)
  if (user && user !== 'loading') {
    return <Redirect to="/ideas/all" />
  } else {
    return (
      <div>
        <section className={styles.violetGradientBackground}>
          <div className={`${styles.section} ${styles.first}`}>
            <SectionText title={t('Never forget an idea. Ever.')}>
              <span className={styles.logo}>Kaizen Board</span>{' '}
              {t(
                'allows you to store your ideas in a safe space and share them with your team. Categorize them to let others know what your are thinking about.'
              )}
            </SectionText>
            <FirstDemoCard />
          </div>
        </section>
        <section className={styles.blackBackground}>
          <div className={`${styles.section} ${styles.second}`}>
            <SecondDemoCard />
            <SectionText title={t('See something you like? Upvote it.')}>
              {t(
                'The app features a full-fledged voting system. Your best ideas are sure to rise to the top!'
              )}
            </SectionText>
          </div>
        </section>
        <section className={styles.greyBackground}>
          <div className={`${styles.section} ${styles.third}`}>
            <SectionText title={t('Categories Galore!')}>
              {t(
                'Want others to have more insight on your ideas? When contributing to the site, filter them into different categories, each color-coded. Site-wide.'
              )}
            </SectionText>
            <ThirdDemoCard />
          </div>
        </section>
      </div>
    )
  }
}
export default Home
