import React from 'react'

import styles from './ProfilePicture.module.css'

const ProfilePicture = ({ uid, url, huge, onClick }) => {
  const defaultClick =
    process.env.NODE_ENV === 'development'
      ? () => console.warn('onClick not implemented')
      : null
  return (
    <img
      alt="Profile"
      className={huge ? styles.hugeProfilePicture : styles.profilePicture}
      src={url || `/api/profile-picture/${uid}`}
      onClick={onClick || defaultClick}
    />
  )
}

export default ProfilePicture
