import * as firestore from 'services/firestore'

const getNotifications = async ({ uid }, callback) => {
  if (callback) {
    return firestore.notifications({ uid }).onSnapshot(snap => {
      const convertDoc = doc => {
        const data = doc.data({ serverTimestamps: 'estimate' })
        return { ...data, id: doc.id }
      }
      callback(snap.docs.map(convertDoc))
    })
  } else {
    const res = firestore.notifications({ uid }).get()
    return res.docs.map(doc => {
      const data = doc.data({ serverTimestamps: 'estimate' })
      return { ...data, id: doc.id }
    })
  }
}

const markNotificationAsRead = nid => {
  return firestore.notification(nid).update({ read: true })
}

export { getNotifications, markNotificationAsRead }
