import firebase from 'firebase'
import * as firestore from 'services/firestore'

const votesCache = {}

const hasVoted = async ({ idea, oid, uid }) => {
  if (!idea.metadata.fromCache) {
    const result = await firebase
      .firestore()
      .collection('votes')
      .where('iid', '==', idea.id)
      .where('oid', '==', oid)
      .where('uid', '==', uid)
      .get()
    const res = result.size > 0
    votesCache[idea.id] = res
    return res
  } else {
    return votesCache[idea.id]
  }
}

const toIdeaData = (oid, uid, docs) => {
  return Promise.all(
    docs.map(async idea => {
      const voted = await hasVoted({ idea, oid, uid })
      const data = idea.data({ serverTimestamps: 'estimate' })
      return {
        ...data,
        id: idea.id,
        votes: data.votes || 0,
        comments: data.comments || 0,
        oid,
        hasVoted: voted,
      }
    })
  )
}

const getGenericIdeas = (selector, modifier) => async (data, callback) => {
  const { oid, uid } = data
  let ref = firestore.ideas(oid).collection(selector)
  if (modifier instanceof Function) {
    ref = modifier(ref)
  }
  if (!callback) {
    const { docs } = await ref.get()
    return await toIdeaData(oid, uid, docs)
  } else {
    return ref.onSnapshot(async ideas => {
      const { docs } = ideas
      const results = await toIdeaData(oid, uid, docs)
      callback(results)
    })
  }
}

const orderByArchived = ref => ref.orderBy('archivedAt', 'desc')
const getCurrentIdeas = getGenericIdeas('current')
const getArchivedIdeas = getGenericIdeas('archived', orderByArchived)
const getRealizingIdeas = getGenericIdeas('realizing')

const addIdea = async ({ uid, oid, idea }) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  const createdAt = timestamp
  const updatedAt = timestamp
  const newIdea = { ...idea, uid, createdAt, updatedAt }
  await firestore.ideas(oid).collection('current').add(newIdea)
}

const deleteIdea = async idea => {
  await firestore.ideas(idea.oid).collection('current').doc(idea.id).delete()
}

const updateIdea = async (oid, id, idea) => {
  const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
  const newIdea = { ...idea, updatedAt }
  await firestore.ideas(oid).collection('current').doc(id).update(newIdea)
}

const archiveIdea = async idea => {
  const options = {
    oid: idea.oid,
    iids: [idea.id],
  }
  await firebase.functions().httpsCallable('ideas-archive')(options)
}

const realizeIdea = async idea => {
  const options = {
    oid: idea.oid,
    iids: [idea.id],
  }
  await firebase.functions().httpsCallable('ideas-realize')(options)
}

const vote = async idea => {
  const options = {
    iid: idea.id,
    oid: idea.oid,
  }
  await firebase.functions().httpsCallable('ideaVote')(options)
}

export {
  archiveIdea,
  realizeIdea,
  getCurrentIdeas,
  getArchivedIdeas,
  getRealizingIdeas,
  addIdea,
  deleteIdea,
  updateIdea,
  vote,
}
