import * as organizationService from 'services/organization'
import * as logsActions from 'reducers/actions/logs'
import {
  UPDATE_USERS,
  REMOVE_USER,
  ADD_USER,
  UPDATE_CURRENT_IDEAS,
  UPDATE_ARCHIVED_IDEAS,
  UPDATE_REALIZING_IDEAS,
} from 'reducers/actions/ideas'
import firebase from 'firebase'
import * as ideasActions from 'reducers/actions/ideas'

export const UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const UPDATE_SELECTED_ORGANIZATION = 'UPDATE_SELECTED_ORGANIZATION'
export const UPDATE_SELECTION_CATEGORIES = 'UPDATE_SELECTION_CATEGORIES'
export const UPDATE_ORGANIZATION_SETTINGS = 'UPDATE_ORGANIZATION_SETTINGS'

const updateSelectedOrganization = value => ({
  type: UPDATE_SELECTED_ORGANIZATION,
  value,
})

const updateOrganizationSettings = ({ oid, settings }) => ({
  type: UPDATE_ORGANIZATION_SETTINGS,
  oid,
  settings,
})

const getNewOrganization = oid => async (dispatch, getState) => {
  const { organizations } = getState()
  if (organizations && (organizations === 'loading' || !organizations[oid])) {
    const user = firebase.auth().currentUser
    const { claims } = await user.getIdTokenResult(true)
    const isAdmin = claims[oid] === 'admin'
    const [_, organization] = await organizationService.fetch({ oid, isAdmin })
    dispatch({ type: UPDATE_CURRENT_IDEAS, ideas: 'loading', oid })
    dispatch({ type: UPDATE_ARCHIVED_IDEAS, ideas: 'loading', oid })
    dispatch({ type: UPDATE_REALIZING_IDEAS, ideas: 'loading', oid })
    dispatch({ type: UPDATE_ORGANIZATION, organization })
    dispatch(ideasActions.getOrgIdeas({ id: oid }))
  }
}

const refreshOrganizations = async (dispatch, getState) => {
  const selectMembers = value => value === 'admin' || value === 'member'
  const { user } = getState()
  const orgs = await Promise.all(
    Object.entries(user.claims)
      .filter(([key, value]) => selectMembers(value))
      .map(([oid]) => {
        const isAdmin = user.claims[oid] === 'admin'
        return organizationService.fetch({ oid, isAdmin })
      })
  )
  const finalOrgs = orgs.filter(org => Boolean(org))
  finalOrgs.forEach(([oid]) => {
    dispatch({ type: UPDATE_CURRENT_IDEAS, ideas: 'loading', oid })
    dispatch({ type: UPDATE_ARCHIVED_IDEAS, ideas: 'loading', oid })
    dispatch({ type: UPDATE_REALIZING_IDEAS, ideas: 'loading', oid })
  })
  dispatch({
    type: UPDATE_ORGANIZATIONS,
    organizations: Object.fromEntries(finalOrgs),
  })
}

const createOrganization = ({ name, domain }) => async dispatch => {
  return await organizationService.createOrganization({ name, domain })
}

const updateOrganization = ({ oid, name, domain, utc }) => async dispatch => {
  await organizationService.updateOrganization({ name, domain, oid, utc })
}

const getUsers = oid => async (dispatch, getState) => {
  const { organizations } = getState()
  if (organizations === 'loading') {
    setTimeout(() => getUsers(oid), 2000)
  } else {
    const organization = organizations[oid]
    if (organization) {
      const users = await organizationService.getUsers(oid)
      dispatch({ type: UPDATE_USERS, users })
    }
  }
}

const updatePeriodicity = ({ oid, periodicity }) => async dispatch => {
  await organizationService.updateOrganization({ oid, periodicity })
  await dispatch(refreshOrganizations)
}

const updateSelectionCategories = ({
  oid,
  selectionCategory,
}) => async dispatch => {
  dispatch({ type: UPDATE_SELECTION_CATEGORIES, oid, cid: selectionCategory })
  try {
    await organizationService.updateOrganization({ oid, selectionCategory })
    await dispatch(refreshOrganizations)
  } catch (error) {
    dispatch(logsActions.crash.add(error))
    dispatch({ type: UPDATE_SELECTION_CATEGORIES, oid, cid: selectionCategory })
  }
}

const addUser = params => async dispatch => {
  const result = await organizationService.addUser(params)
  await dispatch({ type: ADD_USER, user: result, ...params })
  dispatch(refreshOrganizations)
  return result
}

const removeUser = params => async dispatch => {
  const result = await organizationService.removeUser(params)
  await dispatch({ type: REMOVE_USER, ...params })
  dispatch(refreshOrganizations)
  return result
}

const updateStatus = params => async dispatch => {
  const result = await organizationService.updateStatus(params)
  dispatch(refreshOrganizations)
  return result
}

const addCategory = params => async dispatch => {
  const result = await organizationService.addCategory(params)
  await dispatch({ type: UPDATE_ORGANIZATION, organization: result })
  return result
}

const removeCategory = params => async dispatch => {
  const result = await organizationService.removeCategory(params)
  await dispatch({ type: UPDATE_ORGANIZATION, organization: result })
  return result
}

const updateSlackSettings = params => async (dispatch, getState) => {
  const { organizations } = getState()
  const { oid } = params
  const organization = organizations[oid]
  const { ideasElected } = organization.settings.slack || {
    ideasElected: false,
  }
  try {
    const slack = {
      ideasElected: params.ideasElected ? !ideasElected : ideasElected,
    }
    dispatch(updateOrganizationSettings({ oid, settings: { slack } }))
    const sid = 'slack'
    await organizationService.setSettings({ oid, sid, content: slack })
    await dispatch(refreshOrganizations)
  } catch (error) {
    dispatch(logsActions.crash.add(error))
    const slack = { ideasElected }
    dispatch(updateOrganizationSettings({ oid, settings: { slack } }))
  }
}

export {
  createOrganization,
  refreshOrganizations,
  updateOrganization,
  getUsers,
  addUser,
  removeUser,
  updateStatus,
  addCategory,
  removeCategory,
  updatePeriodicity,
  updateSelectionCategories,
  updateSelectedOrganization,
  updateOrganizationSettings,
  updateSlackSettings,
  getNewOrganization,
}
