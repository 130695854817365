const isConnected = ({ user }) => {
  return !!user && user !== 'loading'
}

const traysOpened = ({ sidebarOpened, notificationTrayOpened }) => {
  return { sidebarOpened, notificationTrayOpened }
}

const auth = { isConnected }
const config = { traysOpened }

export { auth, config }
