import * as firestore from 'services/firestore'

const getSettings = async uid => {
  const emailsDoc = await firestore.user(uid).settings.emails.get()
  const slackDoc = await firestore.user(uid).settings.slack.get()
  const emails = emailsDoc.data({ serverTimestamps: 'estimate' }) || null
  const slack = slackDoc.data({ serverTimestamps: 'estimate' }) || null
  return { emails, slack }
}

const setSettings = async ({ uid, sid, content }) => {
  await firestore.user(uid).settings[sid].set(content, { merge: true })
}

export { getSettings, setSettings }
