import React from 'react'
import PropTypes from 'prop-types'

const computeClassName = classNames => {
  return classNames.filter(name => name && name !== '').join(' ')
}

const keepDataAttributes = props => {
  const data = {}
  Object.keys(props).forEach(val => {
    if (val.startsWith('data')) {
      data[val] = props[val]
    }
  })
  return data
}

const Flex = ({
  children,
  inline,
  tag,
  onClick,
  style,
  onSubmit,
  ...props
}) => {
  const overflow = props.overflow ? `overflow-${props.overflow}` : null
  const direction = props.direction || 'row'
  const grow = props.grow ? 'grow' : null
  const align = props.align ? `align-${props.align}` : null
  const justify = props.justify ? `justify-${props.justify}` : null
  const wrap = props.wrap ? `flex-wrap` : null
  const shrink = props.shrink ? 'flex-shrink' : 'flex-no-shrink'
  const className = computeClassName([
    inline ? 'inline-flex' : 'flex',
    direction,
    props.className,
    grow,
    align,
    justify,
    wrap,
    shrink,
    overflow,
  ])
  const dataAttributes = keepDataAttributes(props)
  return React.createElement(
    tag || 'div',
    { onSubmit, className, onClick, style, ...dataAttributes },
    children
  )
}

const propTypes = {
  className: PropTypes.string,
  align: PropTypes.oneOf(['start', 'center', 'baseline']),
  justify: PropTypes.oneOf([
    'center',
    'space-around',
    'space-between',
    'start',
    'end',
  ]),
  shrink: PropTypes.bool,
  overflow: PropTypes.oneOf(['hidden', 'auto']),
}

const Row = props => Flex({ ...props, direction: 'row' })
Row.propTypes = propTypes

const Column = props => Flex({ ...props, direction: 'column' })
Column.propTypes = propTypes

const Grid = ({ children, gap, auto, ...props }) => {
  const gridTemplateColumns = auto
  const gridGap = gap
  const align = props.align ? `align-${props.align}` : null
  const className = computeClassName(['grid', props.className, align])
  const style = { ...props.style, gridTemplateColumns, gridGap }
  return (
    <div className={className} style={style}>
      {children}
    </div>
  )
}
export { Row, Column, Grid }
