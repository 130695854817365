import * as logsServices from 'services/logs'
import * as crashServices from 'services/crash'

const crash = {
  add: error => async (dispatch, getState) => {
    try {
      const { user } = getState()
      const { uid } = user || {}
      return crashServices.crash({ uid, error })
    } catch (error) {
      return true
    }
  },
}

const add = action => async (dispatch, getState) => {
  try {
    if (process.env.NODE_ENV !== 'development') {
      const { user } = getState()
      const { uid } = user
      await logsServices.add({ uid, action })
    }
  } catch (error) {
    dispatch(crash.add(error))
  }
}

export { add, crash }
