const selectCorrectColor = color => {
  if (color.startsWith('var')) {
    return window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(color.substring(4, color.length - 1))
      .trim()
  } else if (color.startsWith('#')) {
    return color.replace('#', '')
  } else {
    return color
  }
}

const shadeColor = (color, percent) => {
  const num = parseInt(selectCorrectColor(color), 16)
  const amt = Math.round(2.55 * percent)
  const R = (num >> 16) + amt
  const B = ((num >> 8) & 0x00ff) + amt
  const G = (num & 0x0000ff) + amt
  const value = (
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)
  return `#${value}`
}

const isDark = color => {
  var c = color.substring(1) // strip #
  var rgb = parseInt(c, 16) // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff // extract red
  var g = (rgb >> 8) & 0xff // extract green
  var b = (rgb >> 0) & 0xff // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  return luma < 128
}

export { shadeColor, isDark }
