import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { Column } from 'components/Layout'
import Spacer from 'components/Spacer'

const masonryContainer = (container, columnsWidth, gap) => {
  if (container) {
    const allStyles = getComputedStyle(container)
    const width = parseInt(allStyles.getPropertyValue('width').slice(0, -2))
    const cardAndGap = columnsWidth + gap
    const numberOfTracks = Math.floor((width + gap) / cardAndGap) || 1
    const trackSize =
      numberOfTracks === 1 ? width : width / numberOfTracks - gap
    return { numberOfTracks, trackSize }
  } else {
    return { trackSize: 0, numberOfTracks: 0 }
  }
}

const Renderer = ({ width, gap, render }) => idea => (
  <Fragment key={idea.id}>
    {render({ idea, width })}
    <Spacer size={12} />
  </Fragment>
)

const useWindowResize = (container, columnsWidth, gap) => {
  const [num, setNum] = useState(0)
  const [trackSize, setTrackSize] = useState(0)
  if (container && num === 0) {
    const res = masonryContainer(container, columnsWidth, gap)
    setNum(res.numberOfTracks)
    setTrackSize(res.trackSize)
  }
  useEffect(() => {
    const fun = event => {
      const results = masonryContainer(container, columnsWidth, gap)
      const { numberOfTracks } = results
      if (!container || numberOfTracks !== num) setNum(numberOfTracks)
      if (trackSize !== results.trackSize) setTrackSize(results.trackSize)
    }
    window.addEventListener('resize', fun)
    return () => window.removeEventListener('resize', fun)
  }, [container, columnsWidth, gap, num, setNum, trackSize, setTrackSize])
  return { numberOfTracks: num, trackSize }
}

const Masonry = ({ items, render, strategy, columnsWidth, gap }) => {
  const [container, setContainer] = useState(null)
  const ref = useCallback(setContainer, [])
  const { numberOfTracks, trackSize } = useWindowResize(
    container,
    columnsWidth,
    gap
  )
  const it = new Array(numberOfTracks).fill(0).map((_, index) => {
    const marginRight = index + 1 === numberOfTracks ? 0 : gap
    const keepCorrectColumn = (_, idx) => idx % numberOfTracks === index
    return (
      <Column key={index} style={{ marginRight, width: trackSize }}>
        {items
          .filter(keepCorrectColumn)
          .map(Renderer({ width: trackSize, gap, render }))}
      </Column>
    )
  })
  return (
    <div
      className="flex row justify-space-between grow"
      style={{ margin: gap }}
      key="masonry-base"
      ref={ref}
    >
      {it}
    </div>
  )
}

export default Masonry
