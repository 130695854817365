import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { Row, Column } from 'components/Layout'
import googleLogo from 'assets/images/google.svg'
import microsoftLogo from 'assets/images/microsoft.svg'
import styles from './Authentication.module.css'
import Title from 'components/Title'
import Spacer from 'components/Spacer'
import { useTranslation } from 'react-i18next'
import Input from 'components/Input/Text'
import Submit from 'components/Input/Submit'
import firebase from 'firebase'
import * as logsActions from 'reducers/actions/logs'

export const GoogleSignIn = ({ signIn }) => {
  const { t } = useTranslation()
  return (
    <Row
      align="center"
      className={styles.googleButton}
      tag="button"
      onClick={() => signIn('google')}
    >
      <img src={googleLogo} alt="Google" className={styles.providerIcon} />
      <span>{t('Sign In With Google')}</span>
    </Row>
  )
}

export const MicrosoftSignIn = ({ signIn }) => {
  const { t } = useTranslation()
  return (
    <Row
      align="center"
      className={styles.microsoftButton}
      tag="button"
      onClick={() => signIn('microsoft')}
    >
      <img
        src={microsoftLogo}
        alt="Microsoft"
        className={styles.providerIcon}
      />
      <span>{t('Sign In With Microsoft')}</span>
    </Row>
  )
}

const convertError = error => {
  switch (error) {
    case 'auth/email-already-in-use':
      return 'Email already-in-use'
    case 'auth/invalid-email':
      return 'Invalid email'
    case 'auth/weak-password':
      return 'Password is too weak'
    case 'auth/wrong-password':
    case 'auth/user-not-found':
    case 'auth/invalid-credential':
      return 'Credentials invalid, please check email or password'
    case 'auth/user-disabled':
      return 'Your account has been disabled. Please, contact your administrator'
    default:
      return 'Unknown error, please contact support'
  }
}

export const EmailSignIn = ({ signIn, create, error, resetPassword }) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const dispatch = useDispatch()
  const disabled = create
    ? email.length === 0 || password.length === 0 || name.length === 0
    : resetPassword
    ? email.length === 0
    : email.length === 0 || password.length === 0
  const onSubmit = async event => {
    event.preventDefault()
    if (resetPassword) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .catch(error => dispatch(logsActions.add(error)))
    } else {
      signIn('email', {
        email,
        password,
        name,
        type: create ? 'create' : 'signin',
      })
    }
  }
  return (
    <Column className={styles.emailSignIn} tag="form" onSubmit={onSubmit}>
      <Input
        label={t('Email')}
        email
        value={email}
        placeholder={t('john.doe@example.com')}
        onChange={event => setEmail(event.target.value)}
      />
      <Link
        to={create || resetPassword ? '/authentication' : '/create-account'}
      >
        {create
          ? t('Already have an account? Log in')
          : resetPassword
          ? t('Remember your password? Connect to your account')
          : t('Don’t have an account? Create one')}
      </Link>
      {create && (
        <Input
          label={t('Name')}
          placeholder={t('John Doe')}
          value={name}
          onChange={event => setName(event.target.value)}
        />
      )}
      {!resetPassword && (
        <Input
          label={t('Password')}
          password
          placeholder={t('mysuperpassword')}
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
      )}
      {!create && !resetPassword && (
        <Link className={styles.resetPassword} to="/reset-password">
          {t('Forgotten password?')}
        </Link>
      )}
      {error && <div>{t(convertError(error))}</div>}
      <Submit disabled={disabled} />
    </Column>
  )
}

export const SignIn = ({ signIn, create, error, resetPassword }) => {
  const { t } = useTranslation()
  return (
    <Column align="center" className={styles.buttonsWrapper}>
      <Spacer size={24} />
      <Title>
        {create
          ? t('Create your account')
          : resetPassword
          ? t('Reset your password')
          : t('Sign in with your account')}
      </Title>
      <Spacer size={24} />
      {!create && !resetPassword && (
        <>
          <div className={styles.subTitle}>{t('With company accounts')}</div>
          <GoogleSignIn signIn={signIn} />
          <MicrosoftSignIn signIn={signIn} />
          <div className={styles.subTitle}>{t('With email and password')}</div>
        </>
      )}
      <EmailSignIn
        signIn={signIn}
        create={create}
        error={error}
        resetPassword={resetPassword}
      />
    </Column>
  )
}

const Authentication = ({ signIn, create, error, resetPassword }) => {
  const user = useSelector(({ user }) => user)
  if (user && user !== 'loading') {
    return <Redirect to="/ideas/all" />
  } else {
    return (
      <SignIn
        signIn={signIn}
        create={create}
        error={error}
        resetPassword={resetPassword}
      />
    )
  }
}

export default Authentication
