import React, { useState, useEffect } from 'react'

import styles from './Main.module.css'

const Title = ({ children, ellipsis }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  useEffect(() => {
    if (ellipsis) {
      const callback = () => setWindowSize(window.innerWidth)
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }
  }, [ellipsis])
  const isSmallScreen = windowSize < 390
  return (
    <h1 className={styles.title}>
      {isSmallScreen & (children.length > 9)
        ? children.slice(0, 9) + '…'
        : children}
    </h1>
  )
}

export default Title
