import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { Column, Row } from 'components/Layout'
import Spacer from 'components/Spacer'
import ProfilePicture from 'components/ProfilePicture'
import Checkbox from 'components/Input/Checkbox'

import { config } from 'reducers/selectors'
import * as configActions from 'reducers/actions/config'
import * as logs from 'reducers/actions/logs'
import { updateSelectedOrganization } from 'reducers/actions/organization'

import ComboChartIcon from 'icons/ComboChart'
import SettingsIcon from 'icons/Settings'

import styles from './Navbar.module.css'

const ChangeOrganizationButton = ({ onClick, name }) => (
  <button
    className="btn active grow"
    style={{ margin: '12px' }}
    onClick={onClick}
  >
    {name}
  </button>
)

const AnalyticsLink = ({ user, oid, onClick }) =>
  user.claims[oid] === 'admin' && (
    <Link
      onClick={onClick}
      to={`/analytics/${oid}`}
      className="btn action"
      style={{ padding: 4, marginRight: 12 }}
    >
      <ComboChartIcon size={32} />
    </Link>
  )

const SettingsLink = ({ oid, onClick }) => (
  <Link
    onClick={onClick}
    to={`/organization/${oid}`}
    className="btn action"
    style={{ padding: 4, marginRight: 12 }}
  >
    <SettingsIcon size={32} />
  </Link>
)

const RenderOrganizationButtons = props => {
  const { user, selectedOrganization, toggleSidebar, organization } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const oid = organization.id
  const logger = action => dispatch(logs.add({ action, oid }))
  const isSelected = oid === selectedOrganization
  const changeOrganization = () => dispatch(updateSelectedOrganization(oid))
  return (
    <Row key={oid} align="center">
      {location.pathname === '/ideas/all' && (
        <Checkbox
          className={styles.checkboxOrg}
          checked={isSelected}
          onClick={() => {
            logger('[Navbar/ProfilePopUp]:CHANGE_ORGANIZATION_CHECKBOX')
            toggleSidebar()
            changeOrganization()
          }}
        />
      )}
      <ChangeOrganizationButton
        name={organization.name}
        onClick={() => {
          logger('[Navbar/ProfilePopUp]:CHANGE_ORGANIZATION_BUTTON')
          changeOrganization()
        }}
      />
      <AnalyticsLink
        user={user}
        oid={oid}
        onClick={() =>
          logger('[Navbar/ProfilePopUp]:GO_TO_ORGANIZATION_ANALYTICS')
        }
      />
      <SettingsLink
        oid={oid}
        onClick={() =>
          logger('[Navbar/ProfilePopUp]:GO_TO_ORGANIZATION_SETTINGS')
        }
      />
    </Row>
  )
}

const RenderOrganizations = props => {
  const { organizations } = props
  const arrayOrgs = Object.values(organizations)
  if (arrayOrgs.length === 0 || organizations === 'loading') {
    return null
  } else {
    return (
      <Fragment>
        <Spacer size={6} />
        <Column className={styles.organizationsPopup}>
          {arrayOrgs.map(organization => (
            <RenderOrganizationButtons
              organization={organization}
              key={organization.id}
              {...props}
            />
          ))}
        </Column>
        <Spacer size={6} />
        <div className={styles.border} />
      </Fragment>
    )
  }
}

const Sidebar = ({ logout, signOut }) => {
  const { user, organizations, selectedOrganization } = useSelector(
    ({ user, organizations, selectedOrganization }) => {
      const isConnected = !!user && user !== 'loading'
      return { user, organizations, selectedOrganization, isConnected }
    }
  )
  const dispatch = useDispatch()
  const { sidebarOpened, notificationTrayOpened } = useSelector(
    config.traysOpened
  )
  const sidebarState = sidebarOpened ? styles.active : styles.inactive
  const className = [sidebarState, styles.sidebar].join(' ')
  const logger = action => dispatch(logs.add({ action }))
  const toggleSidebar = () => {
    if (notificationTrayOpened) {
      dispatch(configActions.toggleNotificationTray)
    }
    logger('[Navbar/ProfilePopUp]:TOGGLE_SIDEBAR')
    dispatch(configActions.toggleSidebar)
  }
  const { t } = useTranslation()
  return !user || user === 'loading' ? null : (
    <Column
      tag="nav"
      className={className}
      align="baseline"
      onClick={toggleSidebar}
    >
      <Column align="center">
        <Spacer size={12} />
        <ProfilePicture huge url={user.profilePicture} />
        <Spacer size={12} />
        <div className={styles.displayName}>{user.displayName}</div>
        <Spacer size={3} />
        <div className={styles.email}>{user.email}</div>
        <Spacer size={12} />
        <Link
          onClick={() => logger('[Navbar/ProfilePopUp]:GO_TO_PROFILE')}
          to="/profile"
          className="btn action"
        >
          {t('See my profile')}
        </Link>
        <Spacer size={12} />
        <div className={styles.border} />
        <RenderOrganizations
          user={user}
          organizations={organizations}
          selectedOrganization={selectedOrganization}
          toggleSidebar={toggleSidebar}
        />
        <Spacer size={12} />
        <button
          className="btn action"
          onClick={async event => {
            await logger('[Navbar/ProfilePopUp]:USER_UNLOGGED')
            signOut(event)
          }}
        >
          {t('Logout')}
        </button>
        <Spacer size={12} />
      </Column>
    </Column>
  )
}

export default Sidebar
