import firebase from 'firebase'
import * as storage from 'services/storage'
import * as users from 'services/users'
import * as ideas from './ideas'
import * as organization from './organization'
import * as config from './config'
import * as userActions from './user'
import * as logsActions from './logs'
import * as auth from 'services/authentication'

export const USER_LOGGED = 'USER_LOGGED'
export const USER_NOT_LOGGED = 'USER_NOT_LOGGED'
export const RESET_STORE = 'RESET_STORE'
export const LOADING_USER = 'LOADING_USER'
export const STOP_LOADING_USER = 'STOP_LOADING_USER'

const userLoggedAction = ({ user, claims, profilePicture }) => ({
  type: USER_LOGGED,
  user: { ...user, claims, profilePicture },
})

const logout = async () => {
  await firebase.auth().signOut()
}

const updateUserInformation = async user => {
  const options = {
    name: user.displayName,
    photoURL: user.photoURL,
    email: user.email,
  }
  await auth.updateUserInformation(options)
}

const loginWithGoogle = async dispatch => {
  const provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('openid')
  await firebase.auth().signInWithPopup(provider)
}

const updateMicrosoftProfilePicture = async (dispatch, credentials) => {
  try {
    const options = {
      uid: credentials.additionalUserInfo.profile.id,
      accessToken: credentials.credential.accessToken,
    }
    await auth.updateMicrosoftProfilePicture(options)
  } catch (error) {
    dispatch(logsActions.add(error))
  }
}

const loginWithMicrosoft = async dispatch => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com')
  provider.addScope('openid')
  const credentials = await firebase.auth().signInWithPopup(provider)
  const { isNewUser, providerId } = credentials.additionalUserInfo
  if (isNewUser && providerId === 'microsoft.com') {
    await updateMicrosoftProfilePicture(dispatch, credentials)
  }
}

const loginWithEmailAndPassword = ({ email, password }) => async dispatch => {
  await firebase.auth().signInWithEmailAndPassword(email, password)
}

const createUserWithLoginAndPassword = ({
  email,
  password,
  name,
}) => async dispatch => {
  const credentials = await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
  await credentials.user.updateProfile({ displayName: name })
  if (process.env.NODE_ENV === 'development') {
    await updateUserInformation({ displayName: name, email })
  } else {
    await updateUserInformation({ displayName: name })
  }
}

const getCustomClaims = async () => {
  const results = await firebase.auth().currentUser.getIdTokenResult(true)
  return results.claims
}

const refreshClaims = async (dispatch, getState) => {
  const { user } = getState()
  const { profilePicture } = user
  const claims = await getCustomClaims()
  await dispatch(userLoggedAction({ user, claims, profilePicture }))
}

const userLogged = user => async (dispatch, getState) => {
  try {
    const profilePicture = await storage.getProfilePictureURL(user.uid)
    const settings = await users.getSettings(user.uid)
    dispatch(userActions.updateSettings(settings))
    dispatch(config.subscribeNotifications(user))
    const claims = await getCustomClaims()
    await dispatch(userLoggedAction({ user, claims, profilePicture }))
    await dispatch(organization.refreshOrganizations)
    await dispatch(ideas.getIdeas)
    dispatch(
      logsActions.add({
        action: '[reducers/actions/auth]:USER_LOGGED',
        uid: user.uid,
      })
    )
  } catch (error) {
    dispatch(logsActions.crash.add(error))
    setTimeout(() => dispatch(userLogged(user)), 2000)
  }
}

const userNotLogged = () => async dispatch => {
  dispatch({ type: USER_NOT_LOGGED })
  dispatch({ type: RESET_STORE })
}

const updateProfilePicture = file => async (dispatch, getState) => {
  const { user } = getState()
  const profilePicture = await storage.uploadNewProfilePicture(user.uid, file)
  await dispatch({ type: USER_LOGGED, user: { ...user, profilePicture } })
}

const loading = { type: LOADING_USER }
const stopLoading = { type: STOP_LOADING_USER }

export {
  logout,
  loginWithGoogle,
  loginWithMicrosoft,
  loginWithEmailAndPassword,
  createUserWithLoginAndPassword,
  userLogged,
  userNotLogged,
  loading,
  stopLoading,
  updateProfilePicture,
  refreshClaims,
}
