import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import firebase from 'firebase'
// import './config/firebase'
import './config/i18n'

import store from './reducers/store'
import * as auth from './reducers/actions/auth'
import App from './App'
import * as logsActions from './reducers/actions/logs'
import './styles/App.css'
import './styles/index.css'
import './styles/global.css'
import * as serviceWorker from './serviceWorker'

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    store.dispatch(auth.userLogged(user))
  } else {
    store.dispatch(auth.userNotLogged())
  }
})

if (process.env.NODE_ENV !== 'development') {
  const errorHandler = ({ error }) => {
    store.dispatch(logsActions.crash.add(error))
    return false
  }

  const promiseHandler = ({ reason }) => {
    store.dispatch(logsActions.crash.add(reason))
  }

  if (window.addEventListener) {
    window.addEventListener('error', errorHandler, true)
    window.addEventListener('unhandledrejection', promiseHandler)
  }
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
