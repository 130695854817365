import firebase from 'firebase'
import * as ideasServices from 'services/ideas'
import * as commentsServices from 'services/comments'
import * as configActions from './config'
import * as logsActions from './logs'

export const UPDATE_CURRENT_IDEAS = 'UPDATE_CURRENT_IDEAS'
export const UPDATE_ARCHIVED_IDEAS = 'UPDATE_ARCHIVED_IDEAS'
export const UPDATE_REALIZING_IDEAS = 'UPDATE_REALIZING_IDEAS'
export const UPDATE_VOTE = 'UPDATE_VOTE'
export const UPDATE_COMMENTS = 'UPDATE_COMMENTS'
export const UPDATE_USERS = 'UPDATE_USERS'
export const REMOVE_USER = 'REMOVE_USER'
export const ADD_USER = 'ADD_USER'
export const UPDATE_IDEA = 'UPDATE_IDEA'
export const ARCHIVE_IDEA = 'ARCHIVE_IDEA'
export const ACTUALIZE_IDEAS = 'ACTUALIZE_IDEAS'

const actualizeIdeas = { type: ACTUALIZE_IDEAS }

const getGenericIdeas = (getter, oid, type) => async dispatch => {
  const unsubscriber = await getter(ideas => dispatch({ type, ideas, oid }))
  await dispatch(configActions.addUnsubscriber(unsubscriber))
}

const getOrgIdeas = org => async (dispatch, getState) => {
  const { user } = getState()
  const { uid } = user
  const ids = { oid: org.id, uid }
  const current = callback => ideasServices.getCurrentIdeas(ids, callback)
  const archived = callback => ideasServices.getArchivedIdeas(ids, callback)
  const realizing = callback => ideasServices.getRealizingIdeas(ids, callback)
  const getters = [
    getGenericIdeas(current, org.id, UPDATE_CURRENT_IDEAS),
    getGenericIdeas(archived, org.id, UPDATE_ARCHIVED_IDEAS),
    getGenericIdeas(realizing, org.id, UPDATE_REALIZING_IDEAS),
  ].map(dispatch)
  await Promise.all(getters)
}

const getIdeas = async (dispatch, getState) => {
  const { organizations } = getState()
  const orgs = Object.values(organizations)
  if (orgs.length > 0) {
    const getAllIdeas = orgs.map(org => dispatch(getOrgIdeas(org)))
    await Promise.all(getAllIdeas)
  } else {
    const emptyIdeas = [
      dispatch({ type: UPDATE_CURRENT_IDEAS, ideas: [] }),
      dispatch({ type: UPDATE_ARCHIVED_IDEAS, ideas: [] }),
      dispatch({ type: UPDATE_REALIZING_IDEAS, ideas: [] }),
    ]
    return Promise.all(emptyIdeas)
  }
}

const addNewIdea = ({ idea, oid }) => async (dispatch, getState) => {
  const { user } = getState()
  if (user) {
    await ideasServices.addIdea({ uid: user.uid, oid, idea })
  } else {
    throw new Error()
  }
}

const archiveIdea = idea => async dispatch => {
  await ideasServices.archiveIdea(idea)
}

const realizeIdea = idea => async dispatch => {
  await ideasServices.realizeIdea(idea)
}

const updateIdea = ({ oid, id, ...idea }) => async dispatch => {
  await ideasServices.updateIdea(oid, id, idea)
  dispatch({
    type: UPDATE_IDEA,
    idea: { id, ...idea, oid, updatedAt: Date.now() },
  })
}

const vote = idea => async (dispatch, getState) => {
  const type = UPDATE_VOTE
  dispatch({ type, idea, increment: !idea.hasVoted })
  try {
    await ideasServices.vote(idea)
  } catch (error) {
    dispatch(logsActions.crash.add(error))
    dispatch({ type, idea, increment: idea.hasVoted })
  }
}

const addComment = ({ idea, content }) => async (dispatch, getState) => {
  const { user } = getState()
  const comment = {
    iid: idea.id,
    oid: idea.oid,
    uid: user.uid,
    content,
  }
  await commentsServices.addComment(comment)
}

const subscriptions = {}

const dispatchUpdateComments = (dispatch, idea) => comments => {
  dispatch({ type: UPDATE_COMMENTS, idea, comments })
  dispatch(getUsers(comments))
}

const subscribeComments = idea => async dispatch => {
  const subscription = subscriptions[idea.id]
  if (!subscription) {
    const options = { oid: idea.oid, iid: idea.id }
    const dispatcher = dispatchUpdateComments(dispatch, idea)
    const unsubscriber = await commentsServices.getComments(options, dispatcher)
    subscriptions[idea.id] = unsubscriber
    return unsubscriber
  } else {
    return subscription
  }
}

const unsubscribeComments = idea => async dispatch => {
  const subscription = subscriptions[idea.id]
  if (subscription instanceof Function) {
    subscription()
    delete subscriptions[idea.id]
    return true
  } else {
    return false
  }
}

const deduplicateUids = comments => {
  return comments.reduce((acc, { uid }) => {
    if (acc.includes(uid)) {
      return acc
    } else {
      return [...acc, uid]
    }
  }, [])
}

const getUsers = comments => async (dispatch, getState) => {
  const uids = deduplicateUids(comments)
  const usersPromises = uids.map(async uid => {
    const doc = await firebase.firestore().collection('users').doc(uid).get()
    return { ...doc.data({ serverTimestamps: 'estimate' }), id: uid }
  })
  const users = await Promise.all(usersPromises)
  dispatch({ type: UPDATE_USERS, users })
}

export {
  archiveIdea,
  realizeIdea,
  getOrgIdeas,
  getIdeas,
  addNewIdea,
  vote,
  subscribeComments,
  unsubscribeComments,
  addComment,
  updateIdea,
  actualizeIdeas,
}
