import firebase from 'firebase'
import * as firestore from 'services/firestore'

const getSlackUrl = async ({ oid }) => {
  const options = { oid }
  const fun = firebase.functions().httpsCallable('slackInstall')
  const { data } = await fun(options)
  return data
}

const createOrganization = async ({ name, domain }) => {
  const creation = firebase.functions().httpsCallable('createOrganization')
  const utc = (new Date().getTimezoneOffset() / 60) * -1
  const { data } = await creation({ name, domain, utc })
  return data
}

const addUser = async params => {
  const add = firebase.functions().httpsCallable('addUserOrganization')
  const result = await add(params)
  return result.data
}

const removeUser = async params => {
  const add = firebase.functions().httpsCallable('removeUserOrganization')
  const result = await add(params)
  return result.data
}

const updateOrganization = async ({
  oid,
  name,
  domain,
  periodicity,
  selectionCategory,
  utc,
}) => {
  const fields = [
    ['oid', oid],
    ['name', name],
    ['domain', domain],
    ['periodicity', periodicity],
    ['selectionCategory', selectionCategory],
    ['utc', utc],
  ].filter(([_, value]) => value !== null)
  const options = Object.fromEntries(fields)
  await firebase.functions().httpsCallable('updateOrganization')(options)
}

const fetch = async ({ oid, isAdmin }) => {
  const orgRef = firestore.organization(oid)
  const organization = await orgRef.get()
  const data = organization.data({ serverTimestamps: 'estimate' })
  if (data) {
    const cats = await orgRef.collection('categories').get()
    const categories = cats.docs.map(cat => {
      const data = cat.data({ serverTimestamps: 'estimate' })
      return { id: cat.id, ...data }
    })
    const slackSettings = isAdmin
      ? await firestore.organizationSetting({ oid, sid: 'slack' }).get()
      : { data: () => null }
    const slack = slackSettings.data({ serverTimestamps: 'estimate' }) || null
    return [
      organization.id,
      { ...data, categories, id: organization.id, settings: { slack } },
    ]
  } else {
    return null
  }
}

const getUsers = async oid => {
  const usersDoc = await firestore.users().where(oid, '==', true).get()
  return usersDoc.docs.map(doc => {
    const data = doc.data({ serverTimestamps: 'estimate' })
    return { ...data, id: doc.id }
  })
}

const updateStatus = async params => {
  const update = firebase
    .functions()
    .httpsCallable('updateUserStatusOrganization')
  const result = await update(params)
  return result.data
}

const addCategory = async ({ name, color, oid }) => {
  await firestore
    .organization(oid)
    .collection('categories')
    .add({ name, color })
  const [_oid, org] = await fetch({ oid })
  return org
}

const removeCategory = async ({ category, oid }) => {
  await firestore
    .organization(oid)
    .collection('categories')
    .doc(category.id)
    .delete()
  const [_oid, org] = await fetch({ oid })
  return org
}

const setSettings = async ({ oid, sid, content }) => {
  await firestore
    .organizationSetting({ oid, sid })
    .set(content, { merge: true })
}

export {
  createOrganization,
  fetch,
  updateOrganization,
  getUsers,
  addUser,
  removeUser,
  updateStatus,
  addCategory,
  removeCategory,
  getSlackUrl,
  setSettings,
}
