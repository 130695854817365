import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from '../assets/translations/en.json'
import fr from '../assets/translations/fr.json'

const resources = { en, fr }

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: process.env.NODE_ENV === 'production' ? 'en' : 'dev',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        if (value instanceof Date) {
          const options = JSON.parse(format)
          return value.toLocaleString(lng, options)
        }
        return value
      },
    },
  })

export default i18n
