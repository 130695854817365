import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Column } from 'components/Layout'
import CookieConsent from 'components/CookieConsent'

import Home from 'views/Home'
import Ideas from 'views/Ideas'
import Add from 'views/Add'
import Edit from 'views/Edit'
import Profile from 'views/Profile'
import OrganizationNew from 'views/Organization/New'
import OrganizationIndex from 'views/Organization/Index'
import Analytics from 'views/Analytics'
import Footer from 'components/Footer'
import Authentication from 'views/Authentication'
import MicrosoftTeams from 'views/MicrosoftTeams'
import Navbar from 'components/Navbar'
import Overlay from 'components/Overlay'

import { useAuthentication } from 'services/authentication'

const IdeasFooter = props => (
  <Fragment>
    <Ideas {...props} />
    <Footer />
  </Fragment>
)

const App = () => {
  const { signIn, signOut, error } = useAuthentication()
  const signingIn = useSelector(({ user }) => user === 'loading')
  return (
    <Fragment>
      <Switch>
        <Route exact path="/microsoft-teams">
          <MicrosoftTeams />
        </Route>
        <Route>
          <CookieConsent />
          <Column className="app">
            <Overlay in={signingIn} />
            <Navbar signOut={signOut} />
            <Column tag="main" grow>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/ideas/all" component={IdeasFooter} />
                <Route exact path="/ideas/add" component={Add} />
                <Route exact path="/ideas/:id" component={IdeasFooter} />
                <Route exact path="/ideas/:id/edit" component={Edit} />
                <Route exact path="/profile" component={Profile} />
                <Route
                  exact
                  path="/organization/new"
                  component={OrganizationNew}
                />
                <Route
                  exact
                  path="/organization/:id"
                  component={OrganizationIndex}
                />
                <Route exact path="/analytics/:oid" component={Analytics} />
                <Route exact path="/authentication">
                  <Authentication signIn={signIn} error={error} />
                </Route>
                <Route exact path="/create-account">
                  <Authentication signIn={signIn} create error={error} />
                </Route>
                <Route exact path="/reset-password">
                  <Authentication signIn={signIn} error={error} resetPassword />
                </Route>
              </Switch>
            </Column>
          </Column>
        </Route>
      </Switch>
    </Fragment>
  )
}

export default App
