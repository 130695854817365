import firebase from 'firebase'
import * as firestore from './firestore'

const crash = ({ uid, error }) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  const userAgent = navigator.userAgent
  const data = { uid, msg: error.message, timestamp, userAgent }
  if (error.stack) {
    data.stacktrace = error.stack
  }
  return firestore.crash().add(data)
}

export { crash }
