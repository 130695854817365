import React from 'react'

import styles from './Input.module.css'

const selectClassName = props => {
  const classes = Object.entries(props)
    .flatMap(([key, value]) => {
      switch (key) {
        case 'bold':
          if (value) {
            return styles.bold
          } else {
            return []
          }
        case 'className':
          return [value]
        case 'resizable':
          if (value) {
            return styles.resizable
          } else {
            return styles.notResizable
          }
        default:
          return []
      }
    })
    .join(' ')
  return `${styles.input} ${classes}`
}

const Input = ({
  label,
  password,
  email,
  labelBold,
  multiline,
  resizable,
  bold,
  ...props
}) => {
  const content = multiline ? (
    <textarea
      {...props}
      className={selectClassName({ ...props, bold, resizable })}
    />
  ) : (
    <input
      {...props}
      type={password ? 'password' : email ? 'email' : 'text'}
      className={selectClassName({ ...props, bold })}
    />
  )
  if (label) {
    const spanClass = `${styles.labelText}  ${labelBold ? styles.bold : ''}`
    return (
      <label className={styles.label}>
        <span className={spanClass}>{label}</span>
        {content}
      </label>
    )
  } else {
    return content
  }
}

export default Input
