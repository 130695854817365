import firebase from 'firebase'
import accountLogo from 'assets/images/account.svg'

const profilePicture = uid => {
  return firebase.storage().ref(`profile-picture/${uid}`)
}

const getProfilePictureURL = async uid => {
  try {
    const storage = profilePicture(uid)
    const url = await storage.getDownloadURL()
    return url
  } catch (error) {
    return accountLogo
  }
}

const uploadNewProfilePicture = async (uid, picture) => {
  const storage = profilePicture(uid)
  await storage.put(picture)
  return storage.getDownloadURL()
}

export { getProfilePictureURL, uploadNewProfilePicture }
