import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import firebase from 'firebase'
import { Row } from 'components/Layout'
import Spacer from 'components/Spacer'

import Toast from 'components/Toast'

const CookieConsent = () => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [closed, setClosed] = useState(false)
  useEffect(() => {
    const stringAnalytics = localStorage.getItem('analyticsEnabled')
    const analyticsEnabled = JSON.parse(stringAnalytics)
    const isBool = typeof analyticsEnabled === 'boolean'
    if (isBool) {
      if (analyticsEnabled) {
        if (firebase.analytics) {
          firebase.analytics()
        }
      } else {
        const measurementId = firebase.app().options.measurementId
        window[`ga-disable-${measurementId}`] = true
      }
    }
    setVisible(!isBool)
  }, [])
  const onDecline = () => {
    localStorage.setItem('analyticsEnabled', JSON.stringify(false))
    const measurementId = firebase.app().options.measurementId
    window[`ga-disable-${measurementId}`] = true
    setClosed(true)
  }
  const onAccept = () => {
    localStorage.setItem('analyticsEnabled', JSON.stringify(true))
    if (firebase.analytics) {
      firebase.analytics()
    }
    setClosed(true)
  }
  if (visible) {
    return (
      <Toast close={closed} onClose={() => setVisible(false)}>
        <Row wrap align="center" className="pad-m">
          {t('In order to improve the site cookies')}
          <div className="grow" />
          <Spacer size={6} />
          <Row shrink className="pad-top">
            <button
              onClick={onDecline}
              style={{ color: 'var(--white)' }}
              className="btn action"
            >
              {t('I rather not')}
            </button>
            <Spacer size={6} />
            <button onClick={onAccept} className="btn active">
              {t('Yes, of course')}
            </button>
          </Row>
        </Row>
      </Toast>
    )
  } else {
    return null
  }
}

export default CookieConsent
