import {
  loginWithGoogle,
  loginWithMicrosoft,
  loginWithEmailAndPassword,
  createUserWithLoginAndPassword,
  logout,
  loading,
  stopLoading,
} from 'reducers/actions/auth'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as logsActions from 'reducers/actions/logs'
import firebase from 'firebase'

const useAuthentication = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const signIn = async (provider, options) => {
    try {
      await dispatch(loading)
      if (provider === 'google') {
        await dispatch(loginWithGoogle)
        setError(null)
      } else if (provider === 'microsoft') {
        await dispatch(loginWithMicrosoft)
        setError(null)
      } else if (provider === 'email') {
        if (options.type === 'create') {
          await dispatch(createUserWithLoginAndPassword(options))
          setError(null)
        } else {
          await dispatch(loginWithEmailAndPassword(options))
          setError(null)
        }
      }
    } catch (error) {
      dispatch(logsActions.crash.add(error))
      setError(error.code)
      await dispatch(stopLoading)
    }
  }
  const signOut = async () => {
    await dispatch(logout)
    await history.push('/')
  }
  return { error, signIn, signOut }
}

const updateMicrosoftProfilePicture = options => {
  const fun = firebase
    .functions()
    .httpsCallable('updateMicrosoftProfilePicture')
  return fun(options)
}

const updateUserInformation = options => {
  return firebase.functions().httpsCallable('updateUserInformation')(options)
}

export {
  useAuthentication,
  updateMicrosoftProfilePicture,
  updateUserInformation,
}
