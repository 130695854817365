import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import * as ideas from 'reducers/actions/ideas'
import * as logsActions from 'reducers/actions/logs'

import Input from 'components/Input/Text'
import Submit from 'components/Input/Submit'
import Select from 'components/Input/Select'
import { Row, Column } from 'components/Layout'
import NotLoggedIn from 'components/NotLoggedIn'
import Loader from 'components/Loader'
import Card from 'components/Card'
import Spacer from 'components/Spacer'
import styles from './Edit.module.css'

const handleChange = setter => event => setter(event.target.value)

const handleSubmit = ({
  dispatch,
  title,
  content,
  category,
  idea,
  setRedirect,
}) => async event => {
  event.preventDefault()
  if (title !== '') {
    const updated = { id: idea.id, oid: idea.oid, title, content, category }
    dispatch(
      logsActions.add({
        action: '[Edit]:UPDATE_IDEA',
        idea: updated,
        oid: idea.oid,
      })
    )
    await dispatch(ideas.updateIdea(updated))
    setRedirect(true)
  }
}

const onCmdClick = onSubmit => event => {
  if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
    onSubmit(event)
  }
}

const NewIdea = ({
  onSubmit,
  title,
  content,
  setTitle,
  setContent,
  category,
  setCategory,
  organization,
  categories,
}) => {
  const { t } = useTranslation()
  const canSend = title !== ''
  return (
    <form onSubmit={onSubmit}>
      <Card pad className="add-idea-form">
        <Input
          label={t('Title')}
          labelBold
          bold
          value={title}
          onChange={handleChange(setTitle)}
          placeholder={t('My Awesome Idea')}
          onKeyDown={onCmdClick(onSubmit)}
        />
        <Input
          label={t('Content')}
          labelBold
          onChange={handleChange(setContent)}
          placeholder={t('How about...')}
          rows={5}
          value={content}
          multiline
          resizable
          onKeyDown={onCmdClick(onSubmit)}
        />
        <Spacer size={6} />
        <Row align="center" justify="center">
          <Select
            options={categories.map(cat => {
              if (cat.name === 'To categorize') {
                return { ...cat, name: t(cat.name) }
              } else {
                return cat
              }
            })}
            selected={category}
            onChange={handleChange(setCategory)}
            label={t('Categories')}
          />
        </Row>
      </Card>
      <Submit
        disabled={!canSend}
        style={{ margin: '1rem 0', textTransform: 'uppercase' }}
        value={t('Submit')}
      />
    </form>
  )
}

const Edit = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { user, organization, categories, ideasLoading, idea } = useSelector(
    ({ user, organizations, currentIdeas, selectedOrganization }) => {
      const ideasLoading = currentIdeas === 'loading'
      const organization =
        (Object.values(organizations) || []).find(
          org => org.id === selectedOrganization
        ) || {}
      const categories = organization.categories || []
      const allRawIdeas = ideasLoading
        ? null
        : currentIdeas[selectedOrganization] || []
      const rawIdea =
        allRawIdeas && allRawIdeas !== 'loading'
          ? allRawIdeas.find(idea => idea.id === id)
          : null
      const idea = rawIdea
        ? (user || {}).uid === rawIdea.uid ||
          ((user || {}).claims || [])[rawIdea.oid] === 'admin'
          ? rawIdea
          : null
        : null
      return { user, organization, categories, ideasLoading, idea }
    }
  )
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const [redirect, setRedirect] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [category, setCategory] = useState('')
  React.useEffect(() => {
    if (idea && !loaded) {
      const cat = categories.find(cat => cat.id === idea.category)
        ? idea.category
        : null
      setTitle(idea.title)
      setContent(idea.content)
      setCategory(cat || '')
      setLoaded(true)
    }
  }, [idea, loaded, categories])
  const onSubmit = handleSubmit({
    dispatch,
    title,
    content,
    category: category !== '' ? category : (categories[0] || {}).id || null,
    idea,
    setRedirect,
  })
  if (ideasLoading) {
    return (
      <Column grow align="center" justify="center">
        <Loader />
      </Column>
    )
  } else if (user) {
    if (idea === null) {
      return <div>404</div>
    } else {
      return (
        <div className="add">
          <div className={styles.wrapper}>
            <Row
              tag="button"
              align="center"
              className={styles.returnButton}
              onClick={() => {
                dispatch(
                  logsActions.add({
                    type: '[Edit]:IDEA_GO_BACK',
                    oid: organization.id,
                  })
                )
                history.goBack()
              }}
            >
              <i className="fas fa-long-arrow-alt-left" />
              {t('Back to ideas list')}
            </Row>
            <h1>{t('Need to edit your great idea?')}</h1>
            <h2>{t('Yeah, sometimes we have to, let’s do it!')}</h2>
            {redirect ? <Redirect to="/ideas/all" /> : null}
            <NewIdea
              onSubmit={onSubmit}
              title={title}
              content={content}
              setTitle={setTitle}
              setContent={setContent}
              category={category}
              setCategory={setCategory}
              organization={organization}
              categories={categories}
            />
          </div>
        </div>
      )
    }
  } else {
    return <NotLoggedIn />
  }
}

export default Edit
