import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveCalendar } from '@nivo/calendar'
import { useTranslation } from 'react-i18next'

import { DateDiff } from 'helpers/date'
import Spacer from 'components/Spacer'
import Loader from 'components/Loader'
import { Row, Column } from 'components/Layout'
import Card from 'components/Card'
import Title from 'components/Title'
import styles from './Analytics.module.css'

const arrayData = (t, { currentIdeas, archivedIdeas, realizingIdeas }) => {
  const allZeros =
    currentIdeas.length === 0 &&
    archivedIdeas.length === 0 &&
    realizingIdeas.length === 0
  return [
    {
      id: 'currentIdeas',
      label: t('Current Ideas'),
      value: currentIdeas.length || (allZeros ? 1 : 0),
      slice: currentIdeas.length,
    },
    {
      id: 'archivedIdeas',
      label: t('Archived Ideas'),
      value: archivedIdeas.length || (allZeros ? 1 : 0),
      slice: archivedIdeas.length,
    },
    {
      id: 'realizingIdeas',
      label: t('Realizing Ideas'),
      value: realizingIdeas.length || (allZeros ? 1 : 0),
      slice: realizingIdeas.length,
    },
  ]
}

const lastMonth = selector => idea => {
  return DateDiff.inDays(new Date(), selector(idea).toDate()) > 30
}

const generateData = (t, period, ideas) => {
  switch (period) {
    case 'all': {
      const currentIdeas = ideas.currentIdeas
      const archivedIdeas = ideas.archivedIdeas
      const realizingIdeas = ideas.realizingIdeas
      return arrayData(t, { currentIdeas, archivedIdeas, realizingIdeas })
    }
    case 'month': {
      const currentIdeas = ideas.currentIdeas.filter(
        lastMonth(idea => idea.createdAt)
      )
      const archivedIdeas = ideas.archivedIdeas.filter(
        lastMonth(idea => idea.archivedAt)
      )
      const realizingIdeas = ideas.realizingIdeas.filter(
        lastMonth(idea => idea.realizedAt)
      )
      const data = arrayData(t, { currentIdeas, archivedIdeas, realizingIdeas })
      return data
    }
    default: {
      const currentIdeas = ideas.currentIdeas
      const archivedIdeas = ideas.archivedIdeas
      const realizingIdeas = ideas.realizingIdeas
      return arrayData(t, { currentIdeas, archivedIdeas, realizingIdeas })
    }
  }
}

const convertPeriodToString = period => {
  switch (period) {
    case 'all':
      return 'Since the beginning'
    case 'month':
      return 'Last 30 days'
    default:
      return 'Since the beginning'
  }
}

const Selector = ({ organization, period, onPrevious, onNext, years }) => {
  const { t } = useTranslation()
  const s = cond => ({
    color: cond ? '#eee' : '#000',
    cursor: cond ? 'not-allowed' : 'pointer',
  })
  return (
    <Row justify="center">
      <div
        style={s(
          years
            ? organization.createdAt.toDate().getFullYear() === period
            : period === 'all'
        )}
        onClick={onPrevious}
      >
        ←
      </div>
      <Spacer size={6} />
      {years ? period : t(convertPeriodToString(period))}
      <Spacer size={6} />
      <div
        style={s(
          years ? new Date().getFullYear() === period : period === 'month'
        )}
        onClick={onNext}
      >
        →
      </div>
    </Row>
  )
}

const generateOnPrevious = (period, setPeriod) => {
  switch (period) {
    case 'month':
      return () => setPeriod('all')
    case 'all':
      return () => setPeriod('all')
    default:
      return () => setPeriod('all')
  }
}

const generateOnNext = (period, setPeriod) => {
  switch (period) {
    case 'month':
      return () => setPeriod('month')
    default:
      return () => setPeriod('month')
  }
}

const generateOnPreviousYears = (organization, period, setPeriod) => {
  if (organization.createdAt.toDate().getFullYear() === period) {
    return () => {}
  } else {
    return () => setPeriod(period - 1)
  }
}

const generateOnNextYears = (period, setPeriod) => {
  if (new Date().getFullYear() === period) {
    return () => {}
  } else {
    return () => setPeriod(period + 1)
  }
}

const CardTitle = ({ children }) => (
  <h2 style={{ marginTop: 0, marginBottom: 12 }}>{children}</h2>
)

const IdeasStateChart = ({ currentIdeas, archivedIdeas, realizingIdeas }) => {
  const { t } = useTranslation()
  const [ideasPiePeriod, setIdeasPiePeriod] = React.useState('month')
  return (
    <>
      <CardTitle>{t('Ideas')}</CardTitle>
      <Card className={styles.minHeight}>
        {currentIdeas === 'loading' ||
        archivedIdeas === 'loading' ||
        realizingIdeas === 'loading' ? (
          <Column align="center" justify="center" grow>
            <Loader />
          </Column>
        ) : (
          <>
            <Spacer size={12} />
            <Selector
              period={ideasPiePeriod}
              onPrevious={generateOnPrevious(ideasPiePeriod, setIdeasPiePeriod)}
              onNext={generateOnNext(ideasPiePeriod, setIdeasPiePeriod)}
            />
            <div className={styles.pieWrap}>
              <ResponsivePie
                data={generateData(t, ideasPiePeriod, {
                  currentIdeas,
                  archivedIdeas,
                  realizingIdeas,
                })}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.4}
                padAngle={2}
                cornerRadius={2}
                colors={{ scheme: 'set1' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabel={d => d.label}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabel={d => d.slice}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#ffffff"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                isInteractive={false}
                legends={[
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    translateY: 56,
                    itemWidth: 120,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </>
        )}
      </Card>
    </>
  )
}

const prependZero = data => {
  if (data < 10) {
    return `0${data}`
  } else {
    return data
  }
}

const generateCalendarData = (period, ideas) => {
  const allDatas = ideas.reduce((acc, val) => {
    const date = val.createdAt.toDate()
    const year = date.getFullYear()
    if (period === year) {
      const key = `${year}-${prependZero(date.getMonth())}-${prependZero(
        date.getDay()
      )}`
      const newAcc = { ...acc, [key]: (acc[key] || 0) + 1 }
      return newAcc
    } else {
      return acc
    }
  }, {})
  return Object.entries(allDatas).map(([day, value]) => ({ day, value }))
}

const IdeasCalendarChart = ({
  organization,
  currentIdeas,
  archivedIdeas,
  realizingIdeas,
}) => {
  const { t } = useTranslation()
  const [ideasCalendarYear, setIdeasCalendarYear] = React.useState(
    new Date().getFullYear()
  )
  const isLoading =
    organization === 'loading' ||
    currentIdeas === 'loading' ||
    archivedIdeas === 'loading' ||
    realizingIdeas === 'loading'
  return (
    <>
      <CardTitle>{t('Ideas created in')}</CardTitle>
      <Card className={styles.minCalendarHeight}>
        {isLoading ? (
          <Column align="center" justify="center" grow>
            <Loader />
          </Column>
        ) : (
          <>
            <Spacer size={12} />
            <Selector
              years
              organization={organization}
              period={ideasCalendarYear}
              onPrevious={generateOnPreviousYears(
                organization,
                ideasCalendarYear,
                setIdeasCalendarYear
              )}
              onNext={generateOnNextYears(
                ideasCalendarYear,
                setIdeasCalendarYear
              )}
            />
            <div className={styles.calendarWrap}>
              <ResponsiveCalendar
                data={generateCalendarData(ideasCalendarYear, [
                  ...currentIdeas,
                  ...archivedIdeas,
                  ...realizingIdeas,
                ])}
                from={`${ideasCalendarYear}-01-01`}
                to={`${ideasCalendarYear}-12-31`}
                emptyColor="#eeeeee"
                colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                yearSpacing={40}
                monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                legends={[
                  {
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left',
                  },
                ]}
                tooltip={({ day, date, value, color, x, y, size }) => (
                  <div>
                    {t(`ideaDate`, { date })} : {value}
                  </div>
                )}
              />
            </div>
            <Spacer size={6} />
          </>
        )}
      </Card>
    </>
  )
}

const RenderOrganizationAnalytics = props => {
  const { t } = useTranslation()
  return (
    <div className={styles.wrapper}>
      <Title>{t('Metrics')}</Title>
      <Spacer size={16} />
      <IdeasStateChart {...props} />
      <Spacer size={16} />
      <IdeasCalendarChart {...props} />
    </div>
  )
}

const Analytics = () => {
  const { t } = useTranslation()
  const { oid } = useParams()
  const data = useSelector(({ organizations, ...store }) => {
    if (organizations !== 'loading') {
      const organization = organizations[oid]
      const currentIdeas = store.currentIdeas[oid] || []
      const archivedIdeas = store.archivedIdeas[oid] || []
      const realizingIdeas = store.realizingIdeas[oid] || []
      return { organization, currentIdeas, archivedIdeas, realizingIdeas }
    } else {
      return 'loading'
    }
  })
  if (data === 'loading') {
    return (
      <Column grow className={styles.wrapper}>
        <Title>{t('Metrics')}</Title>
        <Column grow align="center" justify="center">
          <Loader />
        </Column>
      </Column>
    )
  } else {
    return <RenderOrganizationAnalytics {...data} />
  }
}

export default Analytics
