import firebase from 'firebase'
import * as firestore from 'services/firestore'

const addComment = async ({ iid, oid, uid, content }) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  const createdAt = timestamp
  const updatedAt = timestamp
  const options = { iid, uid, oid, content, createdAt, updatedAt }
  await firebase.firestore().collection('comments').add(options)
}

const toCommentData = docs => {
  return docs.map(comment => {
    const data = comment.data({ serverTimestamps: 'estimate' })
    return { ...data, id: comment.id }
  })
}

const getComments = async ({ oid, iid }, callback) => {
  const ref = firestore.comments({ iid, oid })
  if (!callback) {
    const { docs } = await ref.get()
    return toCommentData(docs)
  } else {
    return ref.onSnapshot(comments => {
      const { docs } = comments
      callback(toCommentData(docs))
    })
  }
}

export { addComment, getComments }
