import React, { Fragment } from 'react'

import styles from './Input.module.css'

const Checkbox = ({ checked, onClick, className, style, small }) => (
  <label data-small={small} className={className} style={style}>
    <input
      className={styles.checkbox}
      type="checkbox"
      checked={checked}
      onChange={onClick}
    />
    <div className={styles.checkboxDisplay}>
      {checked ? (
        <Fragment>
          <div className={styles.checkmarkStem} />
          <div className={styles.checkmarkKick} />
        </Fragment>
      ) : null}
    </div>
  </label>
)

export default Checkbox
