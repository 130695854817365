import * as configServices from 'services/config'
import * as organizationActions from 'reducers/actions/organization'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_NOTIFICATION_TRAY = 'TOGGLE_NOTIFICATION_TRAY'
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
export const ADD_UNSUBSCRIBER = 'ADD_UNSUBSCRIBER'

const toggleSidebar = { type: TOGGLE_SIDEBAR }
const toggleNotificationTray = { type: TOGGLE_NOTIFICATION_TRAY }

const addUnsubscriber = unsubscriber => ({
  type: ADD_UNSUBSCRIBER,
  unsubscriber,
})

const readNotification = dispatch => async notif => {
  if (notif.read || notif.type !== 'ADDED_TO_ORGANIZATION') {
    return notif
  } else {
    await dispatch(organizationActions.getNewOrganization(notif.oid))
    await configServices.markNotificationAsRead(notif.id)
    return { ...notif, read: true }
  }
}

const updateNotifications = notifications => async dispatch => {
  const promiseNotifs = notifications.map(readNotification(dispatch))
  const newNotifs = await Promise.all(promiseNotifs)
  return { type: UPDATE_NOTIFICATIONS, notifications: newNotifs }
}

const subscribeNotifications = ({ uid }) => async dispatch => {
  const action = notifs => dispatch(updateNotifications(notifs))
  const unsubscriber = await configServices.getNotifications({ uid }, action)
  dispatch(addUnsubscriber(unsubscriber))
}

export {
  toggleSidebar,
  toggleNotificationTray,
  updateNotifications,
  subscribeNotifications,
  addUnsubscriber,
}
