import React from 'react'
import { CSSTransition } from 'react-transition-group'

import Loader from 'components/Loader'
import styles from './Overlay.module.css'

const Overlay = props => {
  const className = [props.className, styles.overlay]
    .filter(s => !!s && s !== '')
    .join(' ')
  return (
    <CSSTransition
      in={props.in}
      timeout={200}
      classNames="overlay-transition"
      mountOnEnter={props.mountOnEnter}
    >
      <div onClick={props.onClick} className={className}>
        {props.children === undefined ? <Loader /> : props.children}
      </div>
    </CSSTransition>
  )
}

export default Overlay
