import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './NotLoggedIn.module.css'
import arrowUp from 'assets/images/arrow_up.svg'

const NotLoggedIn = ({ redirectURL }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.main}>
      <h1 className={styles.title}>{t('Seems you’re not logged in.')}</h1>
      <div className={styles.clickHere}>
        <div className={styles.clickHereText}>
          {t('Click here to connect!')}
        </div>
        <img src={arrowUp} alt="Arrow up" style={{ width: '100px' }} />
      </div>
    </div>
  )
}

export default NotLoggedIn
