import React, { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import Title from 'components/Title'
import Spacer from 'components/Spacer'
import { Column, Row } from 'components/Layout'
import Input from 'components/Input/Text'
import Color from 'components/Input/Color'
import Submit from 'components/Input/Submit'
import Select from 'components/Input/Select'
import Checkbox from 'components/Input/Checkbox'
import Callout from 'components/Callout'
import Loader from 'components/Loader'
import NotLoggedIn from 'components/NotLoggedIn'
import Popup from 'components/Popup'
import Card from 'components/Card'
import ProfilePicture from 'components/ProfilePicture'

import { isDark } from 'helpers/colors'
import * as organizationActions from 'reducers/actions/organization'
import * as organizationServices from 'services/organization'
import * as logsActions from 'reducers/actions/logs'

import styles from './Index.module.css'

const domainRegex = /^[\w][-\w]{0,62}(\.\w+)+$/g

const useFormSettings = organization => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [domain, setDomain] = useState('')
  const [utc, setUtc] = useState(organization.utc)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const enabled =
    name !== '' ||
    domain.match(/^[\w][-\w]{0,62}(\.\w+)+$/g) ||
    utc !== organization.utc
  const resetFields = () => {
    setName('')
    setDomain('')
  }
  const onSubmit = async event => {
    event.preventDefault()
    try {
      setError(null)
      setLoading(true)
      const _name = name === '' ? null : name
      const _domain = domain.match(domainRegex) ? domain : null
      const options = {
        oid: organization.id,
        name: _name,
        domain: _domain,
        utc,
      }
      dispatch(
        logsActions.add({
          action: '[Organization/Index]:UPDATE_ORGANIZATION',
          ...options,
        })
      )
      await dispatch(organizationActions.updateOrganization(options))
      await dispatch(organizationActions.refreshOrganizations)
      resetFields()
      setLoading(false)
    } catch (error) {
      dispatch(logsActions.crash.add(error))
      setLoading(false)
      setError(error.message)
    }
  }
  return {
    onSubmit,
    enabled,
    loading,
    error,
    name,
    setName,
    domain,
    setDomain,
    setUtc,
    utc,
  }
}

const RenderError = ({ space, error }) => {
  const { t } = useTranslation()
  if (error) {
    return (
      <React.Fragment>
        <Callout error>{t(error)}</Callout>
        {space ? <Spacer size={12} /> : null}
      </React.Fragment>
    )
  } else {
    return null
  }
}

const RenderOverlayLoader = ({ visible }) => {
  if (visible) {
    return (
      <Row className={styles.loaderCenter} align="center" justify="center">
        <Loader />
      </Row>
    )
  } else {
    return null
  }
}

const RenderCategoryTag = ({ category, organization, editable }) => {
  const { t } = useTranslation()
  const [popup, setPopup] = useState(false)
  const dispatch = useDispatch()
  const oid = organization.id
  const onDelete = category => {
    dispatch(organizationActions.removeCategory({ category, oid }))
  }
  return (
    <React.Fragment>
      <Popup
        open={popup}
        onClose={() => setPopup(false)}
        onAccept={() => {
          dispatch(
            logsActions.add({
              action: '[Organization/Index]:DELETE_CATEGORY',
              cid: category.id,
              oid: organization.id,
            })
          )
          onDelete(category)
        }}
      >
        <span style={{ padding: '0 6px 6px 0', fontWeight: 600 }}>
          {t('Delete category?')}
        </span>
        {category.name}
      </Popup>
      <Row
        className={styles.colorLabel}
        style={{
          background: category.color,
          color: isDark(category.color) ? 'white' : 'black',
        }}
        align="center"
      >
        {category.name === 'To categorize' ? t(category.name) : category.name}
        {editable ? (
          <React.Fragment>
            <Spacer size={6} />
            <i
              className="fas fa-times-circle"
              onClick={() => {
                dispatch(
                  logsActions.add({
                    action: '[Organization/Index]:ON_DELETE_CATEGORY_POPUP',
                    oid,
                    category: category.id,
                  })
                )
                setPopup(true)
              }}
            />
          </React.Fragment>
        ) : null}
      </Row>
    </React.Fragment>
  )
}

const RenderCategories = ({ organization }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [newCategory, setNewCategory] = useState('')
  const [newCategoryColor, setNewCategoryColor] = useState('#FFFFFF')
  const enabled = newCategory !== '' && newCategoryColor
  const categories = organization.categories || []
  const oid = organization.id
  const onSubmit = async event => {
    event.preventDefault()
    const name = newCategory
    const color = newCategoryColor
    dispatch(
      logsActions.add({
        action: '[Organization/Index]:ADD_ORGANIZATION_CATEGORY',
        name,
        color,
      })
    )
    await dispatch(organizationActions.addCategory({ name, color, oid }))
    setNewCategory('')
    setNewCategoryColor('#ffffff')
  }
  const editable = categories.length > 1
  return (
    <React.Fragment>
      <h2 style={{ marginTop: '0px', marginBottom: '12px' }}>
        {t('Categories')}
      </h2>
      <Card pad>
        {categories.length === 0 ? (
          <Callout info>{t('No categories yet. Define yours below.')}</Callout>
        ) : (
          <React.Fragment>
            {editable ? null : (
              <React.Fragment>
                <Callout info>
                  {t('You need at least one category to sort your ideas!')}
                </Callout>
                <Spacer size={6} />
              </React.Fragment>
            )}
            <Row wrap>
              {categories.map(category => (
                <RenderCategoryTag
                  category={category}
                  organization={organization}
                  key={category.name}
                  editable={editable}
                />
              ))}
            </Row>
          </React.Fragment>
        )}
      </Card>
      <Spacer size={12} />
      <h2 style={{ marginTop: '0px', marginBottom: '12px' }}>
        {t('Add new categories')}
      </h2>
      <Card tag="form" pad onSubmit={onSubmit}>
        <Input
          label={t('New Category')}
          placeholder={t('Amazing Ideas')}
          value={newCategory}
          onChange={event => setNewCategory(event.target.value)}
        />
        <Color
          value={newCategoryColor}
          label={t('Category color')}
          onChange={event => setNewCategoryColor(event.target.value)}
        />
        <Submit disabled={!enabled} />
      </Card>
    </React.Fragment>
  )
}

const SlackSettings = ({ organization }) => {
  const { t } = useTranslation()
  const slack = organization.settings.slack || { ideasElected: true }
  const dispatch = useDispatch()
  return (
    <Fragment>
      <div>
        <Row inline align="center" className={styles.addedToSlack}>
          <i className="fas fa-check" style={{ color: 'rgb(39, 212, 56)' }} />
          <Spacer size={3} />
          <span style={{ fontWeight: 500 }}>{t('Added to Slack!')}</span>
        </Row>
      </div>
      <Spacer size={12} />
      <div style={{ fontWeight: 600 }}>{t('Settings')}</div>
      <Spacer size={6} />
      <Row align="center">
        <Checkbox
          small
          checked={slack.ideasElected}
          style={{ paddingRight: 12 }}
          onClick={() => {
            const oid = organization.id
            const sid = 'slack'
            const payload = { oid, sid, ideasElected: true }
            dispatch(
              logsActions.add({
                action:
                  '[Organization/Index]:UPDATE_ORGANIZATION_SLACK_IDEAS_ELECTED',
                oid,
              })
            )
            dispatch(organizationActions.updateSlackSettings(payload))
          }}
        />
        {t('Notify Slack when ideas are elected')}
      </Row>
    </Fragment>
  )
}

const AddToSlack = ({ organization }) => {
  const { t } = useTranslation()
  const [slackUrl, setSlackUrl] = React.useState('')
  React.useEffect(() => {
    let isMounted = true
    if (organization) {
      organizationServices.getSlackUrl({ oid: organization.id }).then(url => {
        if (isMounted) {
          setSlackUrl(url)
        }
      })
    }
    return () => (isMounted = false)
  }, [organization])
  return (
    <div>
      <a href={slackUrl}>
        <img
          alt={t('Add to Slack')}
          height="40"
          width="139"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x,
            https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
        />
      </a>
    </div>
  )
}

const RenderSlackIntegration = ({ organization }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <h2 style={{ marginTop: 0, marginBottom: '12px' }}>
        {t('Integrations')}
      </h2>
      <Card pad>
        <div style={{ fontWeight: 600 }}>{t('Slack')}</div>
        <Spacer size={6} />
        <Callout info>{t('Configure Slack integration')}</Callout>
        <Spacer size={6} />
        {organization.integrations &&
        organization.integrations.includes('slack') ? (
          <SlackSettings organization={organization} />
        ) : (
          <AddToSlack organization={organization} />
        )}
      </Card>
    </React.Fragment>
  )
}

const RenderGeneral = ({ organization }) => {
  const { t } = useTranslation()
  const hooks = useFormSettings(organization)
  const {
    onSubmit,
    setName,
    setDomain,
    enabled,
    error,
    name,
    domain,
    loading,
    setUtc,
    utc,
  } = hooks
  const opacity = loading ? 0 : 1
  const transition = 'opacity .2s'
  return (
    <Fragment>
      <h2 style={{ marginTop: 0, marginBottom: '12px' }}>{t('General')}</h2>
      <Card tag="form" pad onSubmit={onSubmit}>
        <RenderOverlayLoader visible={loading} />
        <Column style={{ opacity, transition }}>
          {organization === null ? (
            <Callout error>{t('No organization')}</Callout>
          ) : (
            <React.Fragment>
              <RenderError error={error} space />
              <div style={{ fontWeight: 600 }}>
                {t('Organization name')}
                <span className={styles.code}>{organization.name}</span>
              </div>
              <Input
                label={t('Rename organization')}
                type="text"
                value={name}
                onChange={event => setName(event.target.value)}
                placeholder="Stark Industries"
              />
              <Callout info>{t('This will be the public name.')}</Callout>
              <Spacer size={12} />
              <div style={{ fontWeight: 600 }}>
                {t('Organization domain name')}
                <span className={styles.code}>{organization.domain}</span>
              </div>
              <Input
                label={t('Change organization domain name')}
                type="text"
                value={domain}
                onChange={event => setDomain(event.target.value.toLowerCase())}
                placeholder="stark-industries.com"
              />
              <Callout warning>
                {t('Be careful, all people with an email address ending')}
              </Callout>
              <Spacer size={12} />
              <div style={{ fontWeight: 600 }}>{t('Timezone')}</div>
              <Spacer size={6} />
              <Select
                options={[
                  { name: 'UTC-12', id: -12 },
                  { name: 'UTC-11', id: -11 },
                  { name: 'UTC-10', id: -10 },
                  { name: 'UTC-9', id: -9 },
                  { name: 'UTC-8', id: -8 },
                  { name: 'UTC-7', id: -7 },
                  { name: 'UTC-6', id: -6 },
                  { name: 'UTC-5', id: -5 },
                  { name: 'UTC-4', id: -4 },
                  { name: 'UTC-3', id: -3 },
                  { name: 'UTC-2', id: -2 },
                  { name: 'UTC-1', id: -1 },
                  { name: 'UTC', id: 0 },
                  { name: 'UTC+1', id: 1 },
                  { name: 'UTC+2', id: 2 },
                  { name: 'UTC+3', id: 3 },
                  { name: 'UTC+4', id: 4 },
                  { name: 'UTC+5', id: 5 },
                  { name: 'UTC+6', id: 6 },
                  { name: 'UTC+7', id: 7 },
                  { name: 'UTC+8', id: 8 },
                  { name: 'UTC+9', id: 9 },
                  { name: 'UTC+10', id: 10 },
                  { name: 'UTC+11', id: 11 },
                  { name: 'UTC+12', id: 12 },
                ]}
                selected={utc}
                onChange={event => setUtc(parseInt(event.target.value))}
              />
              <Submit disabled={!enabled} marginTop />
            </React.Fragment>
          )}
        </Column>
      </Card>
    </Fragment>
  )
}

// const RenderIdeas = ({ organization }) => {
//   const { t } = useTranslation()
//   const dispatch = useDispatch()
//   const options = [
//     { name: t('1 week'), id: '1w' },
//     { name: t('2 weeks'), id: '2w' },
//     { name: t('3 weeks'), id: '3w' },
//     { name: t('1 month'), id: '1m' },
//     { name: t('2 months'), id: '2m' },
//   ]
//   const [loading, setLoading] = useState(false)
//   const opacity = loading ? 0 : 1
//   const transition = 'opacity .2s'
//   return (
//     <Fragment>
//       <h2 style={{ marginTop: 0, marginBottom: '12px' }}>
//         {t('Ideas Settings')}
//       </h2>
//       <Card pad>
//         <RenderOverlayLoader visible={loading} />
//         <Column style={{ opacity, transition }}>
//           <div style={{ fontWeight: 600 }}>
//             {t('Change idea selection perodicity')}
//           </div>
//           <Spacer size={6} />
//           <Row>
//             <Select
//               options={options}
//               label={t('Periodicity')}
//               selected={organization.periodicity}
//               onChange={async event => {
//                 try {
//                   setLoading(true)
//                   const periodicity = event.target.value
//                   const oid = organization.id
//                   dispatch(
//                     logsActions.add({
//                       action:
//                         '[Organization/Index]:UPDATE_ORGANIZATION_PERIODICITY',
//                       oid,
//                       periodicity,
//                     })
//                   )
//                   await dispatch(
//                     organizationActions.updatePeriodicity({ oid, periodicity })
//                   )
//                   setLoading(false)
//                 } catch (error) {
//                   dispatch(logsActions.crash.add(error))
//                   setLoading(false)
//                 }
//               }}
//             />
//           </Row>
//           <Spacer size={6} />
//           <Callout info>{t('Periodically, an idea will be selected')}</Callout>
//           <Spacer size={12} />
//           <div style={{ fontWeight: 600 }}>
//             {t('Choose idea selection categories')}
//           </div>
//           <Spacer size={6} />
//           <Column>
//             {organization.categories.map(cat => {
//               const whiteText = isDark(cat.color)
//               const checked = organization.selectionCategories.includes(cat.id)
//               return (
//                 <Row key={cat.id} align="center" style={{ padding: '6px 0' }}>
//                   <Checkbox
//                     checked={checked}
//                     onClick={async event => {
//                       const selectionCategory = cat.id
//                       const oid = organization.id
//                       dispatch(
//                         logsActions.add({
//                           action:
//                             '[Organization/Index]:UPDATE_ORGANIZATION_SELECTION_CATEGORIES',
//                           oid,
//                           selectionCategory,
//                           futureState: !checked,
//                         })
//                       )
//                       await dispatch(
//                         organizationActions.updateSelectionCategories({
//                           oid,
//                           selectionCategory,
//                         })
//                       )
//                     }}
//                   />
//                   <span
//                     className={styles.categoryElection}
//                     style={{
//                       background: cat.color,
//                       color: whiteText ? 'white' : 'inherit',
//                     }}
//                   >
//                     {cat.name === 'To categorize' ? t(cat.name) : cat.name}
//                   </span>
//                 </Row>
//               )
//             })}
//           </Column>
//           <Spacer size={6} />
//           <Callout info>{t('Periodically, from this category')}</Callout>
//         </Column>
//       </Card>
//     </Fragment>
//   )
// }

const RenderSettings = ({ user, organization }) => {
  return (
    <React.Fragment>
      <RenderCategories organization={organization} />
      <Spacer size={12} />
      <RenderGeneral organization={organization} />
      {/* <Spacer size={12} />
      <RenderIdeas organization={organization} /> */}
      <Spacer size={12} />
      <RenderSlackIntegration organization={organization} user={user} />
    </React.Fragment>
  )
}

const RenderUser = ({ user, organization, editable, setLoading }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isAdmin = (organization.admin || []).includes(user.id)
  const [popup, setPopup] = useState(false)
  const myself = useSelector(({ user }) => user)
  const oid = organization.id
  const uid = user.id
  const onSelect = async event => {
    if (myself.uid !== user.id) {
      try {
        setLoading(true)
        const status = event.target.value
        const options = { oid, uid, status }
        dispatch(
          logsActions.add({
            action: '[Organization/Index]:UPDATE_ORGANIZATION_USER_STATUS',
            oid,
            tid: uid,
            status,
          })
        )
        await dispatch(organizationActions.updateStatus(options))
        setLoading(false)
      } catch (error) {
        dispatch(logsActions.crash.add(error))
        setLoading(false)
      }
    }
  }
  const openPopup = () => {
    if (myself.uid !== user.id) {
      dispatch(
        logsActions.add({
          action: '[Organization/Index]:ORGANIZATION_OPEN_DELETE_MEMBER_POPUP',
          oid,
        })
      )
      setPopup(true)
    }
  }
  const color = myself.uid === user.id ? 'grey' : 'rgb(236, 0, 0)'
  const removeIconStyle = { fontSize: '1.5rem', color }
  return (
    <React.Fragment>
      <Popup
        open={popup}
        onClose={() => {
          dispatch(
            logsActions.add({
              action: '[Organization/Index]:CLOSE_ORGANIZATION_MEMBER_DELETE',
            })
          )
          setPopup(false)
        }}
        onAccept={async () => {
          try {
            setLoading(true)
            const options = { oid, uid }
            dispatch(
              logsActions.add({
                action: '[Organization/Index]:DELETE_ORGANIZATION_MEMBER',
                oid,
                target: uid,
              })
            )
            await dispatch(organizationActions.removeUser(options))
            setLoading(false)
          } catch (error) {
            dispatch(logsActions.crash.add(error))
            setLoading(false)
          }
        }}
      >
        <span style={{ padding: '0 6px 6px 0', fontWeight: 600 }}>
          {t('Remove user from organization?')}
        </span>
        {user.name}
      </Popup>
      <Row wrap align="center" style={{ padding: '12px 0' }}>
        <Row align="center">
          <ProfilePicture uid={user.id} />
          <Spacer size={6} />
          <Column>
            <div className={styles.memberName}>{user.name}</div>
            <div className={styles.memberEmail}>{user.email}</div>
          </Column>
        </Row>
        <div className="grow" />
        {editable ? (
          <React.Fragment>
            <select
              disabled={myself.uid === uid}
              className={styles.selectMemberRole}
              value={isAdmin ? 'admin' : 'member'}
              onChange={onSelect}
            >
              <option value="admin">{t('Admin')}</option>
              <option value="member">{t('Member')}</option>
            </select>
            <Spacer size={6} />
            <button
              style={{ padding: 0, cursor: 'pointer' }}
              onClick={openPopup}
            >
              <i style={removeIconStyle} className="fas fa-times-circle" />
            </button>
          </React.Fragment>
        ) : (
          <div className={styles.selectMemberRole} style={{ cursor: 'auto' }}>
            {t(isAdmin ? 'Admin' : 'Member')}
          </div>
        )}
      </Row>
    </React.Fragment>
  )
}

const useFormMembers = oid => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('member')
  const [error, setError] = useState(null)
  const onSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    try {
      const payload = { oid, email, status }
      dispatch(
        logsActions.add({
          action: '[Organization/Index]:ADD_ORGANIZATION_USER',
          oid,
          email,
          status,
        })
      )
      await dispatch(organizationActions.addUser(payload))
      await dispatch(organizationActions.getUsers(oid))
      setEmail('')
      setStatus('member')
      setError(null)
    } catch (error) {
      dispatch(logsActions.crash.add(error))
      setError(error)
    }
    setLoading(false)
  }
  return { onSubmit, loading, error, email, setEmail, status, setStatus }
}

const RenderMembers = ({ organization, users, editable }) => {
  const { t } = useTranslation()
  const members = [
    ...(organization.admin || []),
    ...(organization.users || []),
  ].filter(member => users.find(user => user.id === member))
  const oid = organization.id
  const state = useFormMembers(organization.id)
  const { onSubmit, loading, error, email, setEmail, status, setStatus } = state
  const opacityStyle = { opacity: loading ? 0 : 1, transition: 'opacity .2s' }
  const isMember = status === 'member'
  const leftPillStyle = isMember ? styles.activeLeftPill : styles.leftPill
  const rightPillStyle = !isMember ? styles.activeRightPill : styles.rightPill
  const [membersLoading, setMembersLoading] = useState(false)
  const membersOpacityStyle = {
    opacity: membersLoading ? 0 : 1,
    transition: 'opacity .2s',
  }
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      {editable ? (
        <React.Fragment>
          <h2 style={{ marginTop: 0, marginBottom: '12px' }}>
            {t('Add new member')}
          </h2>
          <Card tag="form" pad onSubmit={onSubmit}>
            <RenderOverlayLoader visible={loading} />
            <Column style={opacityStyle}>
              <RenderError error={error} space />
              <span style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                {t('Status')}
              </span>
              <Row style={{ margin: '0.5rem 0' }}>
                <label className={leftPillStyle}>
                  <input
                    type="radio"
                    onClick={() => {
                      dispatch(
                        logsActions.add({
                          action:
                            '[Organization/Index]:SET_ORGANIZATION_NEW_MEMBER_STATUS',
                          oid,
                          status: 'member',
                        })
                      )
                      setStatus('member')
                    }}
                    style={{ display: 'none' }}
                  />
                  {t('Member')}
                </label>
                <label className={rightPillStyle}>
                  <input
                    type="radio"
                    style={{ display: 'none' }}
                    onClick={() => {
                      dispatch(
                        logsActions.add({
                          action:
                            '[Organization/Index]:SET_ORGANIZATION_NEW_MEMBER_STATUS',
                          oid,
                          status: 'admin',
                        })
                      )
                      setStatus('admin')
                    }}
                  />
                  {t('Admin')}
                </label>
              </Row>
              <Input
                label={t('User email')}
                type="text"
                value={email}
                onChange={event => setEmail(event.target.value)}
                placeholder="tony@stark-industries.com"
              />
              <Submit disabled={email === ''} />
            </Column>
          </Card>
          <Spacer size={12} />
        </React.Fragment>
      ) : null}
      <h2 style={{ marginTop: 0, marginBottom: '12px' }}>{t('Members')}</h2>
      <Card pad>
        <RenderOverlayLoader visible={membersLoading} />
        <Column style={membersOpacityStyle}>
          {organization === null ? (
            <Callout error>{t('No organization')}</Callout>
          ) : (
            <React.Fragment>
              {members.length === 0 ? (
                <Spacer size={12} />
              ) : (
                members.map(member => {
                  const user = users.find(user => user.id === member)
                  return (
                    <RenderUser
                      editable={editable}
                      key={user.id}
                      user={user}
                      organization={organization}
                      setLoading={setMembersLoading}
                    />
                  )
                })
              )}
            </React.Fragment>
          )}
        </Column>
      </Card>
    </React.Fragment>
  )
}

const MEMBERS = 'members'
const SETTINGS = 'settings'

const TABS = [MEMBERS, SETTINGS]

const TabSelector = ({ isMembers, onMembersClick, onSettingsClick }) => {
  const { t } = useTranslation()
  const leftPillClass = isMembers ? styles.activeLeftPill : styles.leftPill
  const rightPillClass = !isMembers ? styles.activeRightPill : styles.rightPill
  return (
    <Row align="center" justify="center">
      <button className={leftPillClass} onClick={onMembersClick}>
        {t('Members')}
      </button>
      <button className={rightPillClass} onClick={onSettingsClick}>
        {t('Settings')}
      </button>
    </Row>
  )
}

const getSearchParams = search => {
  return Object.fromEntries(
    search
      .slice(1)
      .split('&')
      .filter(t => Boolean(t))
      .map(t => t.split('='))
  )
}

const toSearchString = searchParams => {
  const body = Object.entries(searchParams)
    .map(t => t.join('='))
    .join('&')
  return `?${body}`
}

const RenderAdminSide = ({ organization, users, user }) => {
  const { pathname, search } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const searchParams = getSearchParams(search)
  const initialTab = TABS.includes(searchParams.tab)
    ? searchParams.tab
    : MEMBERS
  const [tab, setTab] = useState(initialTab)
  const isMembers = tab === MEMBERS
  const updateTab = id => () => {
    setTab(id)
    const search = toSearchString({ ...searchParams, tab: id })
    history.replace({ pathname, search })
    dispatch(
      logsActions.add({
        action: '[Organization/Index]:SET_ORGANIZATION_TAB',
        tab: id,
      })
    )
  }
  const leftPillClick = updateTab(MEMBERS)
  const rightPillClick = updateTab(SETTINGS)
  return (
    <React.Fragment>
      <TabSelector
        isMembers={isMembers}
        onMembersClick={leftPillClick}
        onSettingsClick={rightPillClick}
      />
      <Spacer size={12} />
      {isMembers ? (
        <RenderMembers editable organization={organization} users={users} />
      ) : (
        <RenderSettings organization={organization} user={user} />
      )}
    </React.Fragment>
  )
}

const RenderAdminOrMember = ({ admin, ...props }) => {
  if (admin) {
    return <RenderAdminSide {...props} />
  } else {
    return <RenderMembers {...props} />
  }
}

const useFullyLoadOrganization = ({ organization }) => {
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)
  React.useEffect(() => {
    const dispatcher = async () => {
      await dispatch(organizationActions.getUsers(organization.id))
      setLoaded(true)
    }
    if (organization && organization.id && !loaded) {
      dispatcher()
    }
  }, [organization, dispatch, loaded])
  return loaded
}

const organizationSelector = id => ({ organizations, users, user }) => {
  if (organizations === 'loading') {
    return { isOrganizationLoading: true, organization: {}, users }
  } else {
    const organization = organizations[id]
    return { isOrganizationLoading: false, organization, users, user }
  }
}

const Index = props => {
  const { id } = useParams()
  const state = useSelector(organizationSelector(id))
  const { organization, isOrganizationLoading, users, user } = state
  useFullyLoadOrganization({ organization })
  const isUser = !!user && user !== 'loading'
  const isAdmin = isUser && user.claims[id] === 'admin'
  if (isOrganizationLoading) {
    return (
      <Column grow align="center" justify="center">
        <Loader />
      </Column>
    )
  } else if (isUser) {
    if (organization) {
      return (
        <Column align="center">
          <div className={styles.wrapper}>
            <Title>{organization.name}</Title>
            <Spacer size={12} />
            <RenderAdminOrMember
              organization={organization}
              users={users}
              admin={isAdmin}
              user={user}
            />
          </div>
        </Column>
      )
    } else {
      return <div>404</div>
    }
  } else {
    return <NotLoggedIn />
  }
}

export default Index
