import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
// import { Link } from 'react-router-dom'

import { Row } from 'components/Layout'

import styles from './Footer.module.css'

// eslint-disable-next-line
const LegalTerms = () => {
  // const { t } = useTranslation()
  return (
    <Fragment>
      <div className={styles.smallPadding}>
        © 2020 Guillaume Hivert & Damien Authier
      </div>
      {/*<Link to="/legal-terms" className={styles.legalTermsLink}>
        {t('Legal terms')}
      </Link>*/}
    </Fragment>
  )
}

const Feedback = () => {
  const { t } = useTranslation()
  return (
    <a
      href="mailto:hivert.is.coming@gmail.com"
      className={styles.legalTermsLink}
    >
      {t('An idea? A feedback? Please, send us a message!')}
    </a>
  )
}

const BugReport = () => {
  const { t } = useTranslation()
  return (
    <a
      href="https://hivertiscoming.typeform.com/to/Qe5eRyIX"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.legalTermsLink}
    >
      {t('You encountered a bug? Please, fill our bug report form!')}
    </a>
  )
}

const Footer = () => {
  return (
    <Row wrap className={styles.footer}>
      <LegalTerms />
      <div className="grow" />
      <Feedback />
      <BugReport />
    </Row>
  )
}

export default Footer
