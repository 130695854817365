import firebase from 'firebase'
import * as firestore from 'services/firestore'

const add = async ({ uid, action }) => {
  try {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    const results = await firestore.logs().add({ uid, ...action, timestamp })
    return results
  } catch (error) {
    throw error
  }
}

export { add }
