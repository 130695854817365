import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Column } from 'components/Layout'
import Loader from 'components/Loader'
import {
  GoogleSignIn,
  MicrosoftSignIn,
  EmailSignIn,
} from 'views/Authentication'
import styles from './MicrosoftTeams.module.css'
import firebase from 'firebase'
import { useAuthentication } from 'services/authentication'
import { useTranslation } from 'react-i18next'

const getSettings = teams =>
  new Promise(resolve => teams.settings.getSettings(resolve))

const configureTeams = (
  setLoading,
  setCompleted,
  setSetuped,
  oid
) => async teams => {
  teams.initialize()
  teams.settings.setValidityState(true)
  teams.settings.registerOnSaveHandler(event => {
    setLoading(true)
    teams.settings.setSettings(
      {
        entityId: oid,
        configName: 'Kaizen Board',
        websiteUrl: 'https://kaizenbox.eu.ngrok.io',
        contentUrl: 'https://kaizenbox.eu.ngrok.io',
        removeUrl: 'https://kaizenbox.eu.ngrok.io',
      },
      (status, reason) => {
        console.log(status)
        console.log(reason)
        teams.settings.getSettings(settings => {
          console.log(settings)
          const options = { oid, settings }
          firebase
            .functions()
            .httpsCallable('teams-addTeams')(options)
            .then(() => {
              setLoading(false)
              setCompleted(true)
              event.notifySuccess()
            })
        })
      }
    )
  })
  setSetuped(true)
}

const useTeams = (setLoading, setCompleted) => {
  const [tempOrg, setTempOrg] = useState(null)
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [setuped, setSetuped] = useState(false)
  const {
    organizations,
    ...state
  } = useSelector(({ organizations, user }) => ({ organizations, user }))
  useEffect(() => {
    if (state.user && state.user !== 'loading' && selectedOrganization) {
      import('@microsoft/teams-js').then(
        configureTeams(
          setLoading,
          setCompleted,
          setSetuped,
          selectedOrganization
        )
      )
    }
  }, [state, selectedOrganization, setLoading, setCompleted])
  const user =
    state.user === 'loading' ? { claims: {} } : state.user || { claims: {} }
  const adminsOrgs = Object.entries(state.organizations || {}).filter(
    ([id, org]) => user.claims[id] === 'admin'
  )
  if (adminsOrgs.length > 0 && !tempOrg) setTempOrg(adminsOrgs[0][0])
  return [
    state.user,
    adminsOrgs,
    tempOrg,
    setTempOrg,
    selectedOrganization,
    setSelectedOrganization,
    setuped,
  ]
}

const MicrosoftTeams = () => {
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [
    user,
    adminsOrgs,
    tempOrg,
    setTempOrg,
    selectedOrganization,
    setSelectedOrganization,
    setuped,
  ] = useTeams(setLoading, setCompleted)
  const { signIn, error } = useAuthentication()
  const { t } = useTranslation()
  return (
    <Column style={{ height: '100%' }} align="center" justify="center">
      <img src="icon.png" className={styles.logo} alt="Logo" />
      {!completed && adminsOrgs.length === 0 && (
        <div className={styles.help}>
          {t(
            'Clicking on the button will open a browser. After authenticating, you can close the opened page.'
          )}
        </div>
      )}
      {adminsOrgs.length > 0 ? (
        <div className={styles.help}>
          {t('Choose the organization that you want to add the integration.')}
        </div>
      ) : null}
      {setuped && (
        <div className={styles.help}>
          {t('You can now save the connector!')}
        </div>
      )}
      <div style={{ height: 12 }} />
      {user === 'loading' ? (
        <Loader />
      ) : user !== null ? null : (
        <>
          <GoogleSignIn signIn={signIn} />
          <MicrosoftSignIn signIn={signIn} />
          <EmailSignIn signIn={signIn} error={error} />
        </>
      )}
      {loading && <Loader />}
      {adminsOrgs.length === 0 || selectedOrganization ? null : (
        <>
          <select
            value={tempOrg}
            onChange={event => setTempOrg(event.target.value)}
          >
            {adminsOrgs.map(([id, value]) => (
              <option value={id}>{value.name}</option>
            ))}
          </select>
          <div style={{ height: 12 }} />
          <button
            className="btn active"
            onClick={() => setSelectedOrganization(tempOrg)}
          >
            {t('Submit')}
          </button>
        </>
      )}
      {completed && (
        <div className={styles.help}>{t('You can now close the window!')}</div>
      )}
    </Column>
  )
}

export default MicrosoftTeams
