import firebase from 'firebase'

const fire = () => firebase.firestore()

const organizations = () => fire().collection('organizations')
const organization = oid => organizations().doc(oid)

const organizationSetting = ({ oid, sid }) =>
  organization(oid).collection('settings').doc(sid)

const users = () => fire().collection('users')
const user = uid => {
  const doc = users().doc(uid)
  const settings = doc.collection('settings')
  const emails = settings.doc('emails')
  const slack = settings.doc('slack')
  return {
    doc: doc,
    get: () => doc.get(),
    settings: {
      col: settings,
      get: () => settings.get(),
      onSnapshot: callback => settings.onSnapshot(callback),
      emails: {
        doc: emails,
        get: () => emails.get(),
        onSnapshot: callback => emails.onSnapshot(callback),
        set: (...args) => emails.set(...args),
      },
      slack: {
        doc: slack,
        get: () => slack.get(),
        onSnapshot: callback => slack.onSnapshot(callback),
        set: (...args) => slack.set(...args),
      },
    },
  }
}

const comments = ({ iid, oid }) => {
  return fire()
    .collection('comments')
    .where('iid', '==', iid)
    .where('oid', '==', oid)
    .orderBy('createdAt', 'asc')
}
const comment = cid => fire().collection('comments').doc(cid)

const notifications = ({ uid }) =>
  fire().collection('notifications').where('uid', '==', uid)

const notification = id => fire().collection('notifications').doc(id)

const ideas = oid => fire().collection('ideas').doc(oid)

const logs = () => fire().collection('analytics')

const crash = () => fire().collection('crashes')

export {
  organizations,
  organization,
  users,
  user,
  comments,
  comment,
  ideas,
  notifications,
  notification,
  organizationSetting,
  logs,
  crash,
}
