import React from 'react'

import styles from './Callout.module.css'

const Callout = ({ children, info, error, success, warning }) => (
  <div
    className={styles.callout}
    data-info={info}
    data-error={error}
    data-success={success}
    data-warning={warning}
  >
    {children}
  </div>
)

export default Callout
