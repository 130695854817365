import React from 'react'

import styles from './Input.module.css'

const Submit = ({ marginTop, ...props }) => {
  return (
    <input
      {...props}
      className={[
        styles.submit,
        marginTop ? styles.marginTop : '',
        props.disabled ? 'disabled' : 'active',
      ]
        .filter(s => !!s && s !== '')
        .join(' ')}
      type="submit"
    />
  )
}

export default Submit
