import React from 'react'

import styles from './Input.module.css'

const Input = ({ label, ...props }) => {
  const content = (
    <React.Fragment>
      <input
        {...props}
        type="color"
        className={styles.color}
        style={{ background: props.value }}
      />
      <div className={styles.colorBlock} style={{ background: props.value }} />
    </React.Fragment>
  )
  if (label) {
    return (
      <label className={styles.label}>
        <span className={styles.labelText}>{label}</span>
        {content}
      </label>
    )
  } else {
    return content
  }
}

export default Input
