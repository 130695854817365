import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Input from 'components/Input/Text'
import Loader from 'components/Loader'
import { Row } from 'components/Layout'

import * as ideas from 'reducers/actions/ideas'
import * as logsActions from 'reducers/actions/logs'
import styles from './IdeaCard.module.css'

const shouldRoundCorner = (followUp, displayDate, isAuthor) => {
  if (followUp && !displayDate) {
    return { borderRadius: 10 }
  } else if (isAuthor) {
    return { borderTopRightRadius: 0, borderTopLeftRadius: 10 }
  } else {
    return null
  }
}

const DisplayAuthorName = ({ followUp, name }) => {
  if (followUp) {
    return null
  } else {
    return <h4 className={styles.commentsTitle}>{name}</h4>
  }
}

const DisplayDate = ({ displayed, date, followUp }) => {
  if (displayed) {
    const datePadding = followUp ? { paddingTop: '6px' } : null
    return (
      <div className={styles.commentDate} style={datePadding}>
        {date}
      </div>
    )
  } else {
    return null
  }
}

const Comment = ({ followUp, displayDate, comment, user }) => {
  const { authorName, content } = comment
  const isAuthor = user.uid === comment.uid
  const roundedCorner = shouldRoundCorner(followUp, displayDate, isAuthor)
  const commentDate = comment.createdAt.toDate()
  const localeDate = commentDate.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: 'long',
  })
  return (
    <div style={{ textAlign: isAuthor ? 'right' : 'left' }}>
      <DisplayAuthorName followUp={followUp} name={authorName} />
      <DisplayDate
        displayed={displayDate}
        date={localeDate}
        followUp={followUp}
      />
      <div className={styles.commentContent} style={roundedCorner}>
        <p className={styles.commentContentText}>{content}</p>
      </div>
    </div>
  )
}

const DisplayComment = ({ user, comments, comment, index }) => {
  const prev = comments[index - 1] || { uid: null, createdAt: { seconds: 0 } }
  const followUp = prev.uid === comment.uid
  const actualCreatedAt = comment.createdAt || Date.now() / 1000
  const lessTenMin = actualCreatedAt.seconds - prev.createdAt.seconds > 600
  const displayDate = !followUp || lessTenMin
  return (
    <Comment
      comment={comment}
      followUp={followUp}
      displayDate={displayDate}
      user={user}
    />
  )
}

const DisplayComments = ({ comments, user }) => {
  const { t } = useTranslation()
  if (comments === 'loading') {
    return (
      <Row align="center" justify="center" style={{ padding: 24 }}>
        <Loader />
      </Row>
    )
  } else if (comments.length > 0) {
    return comments.map((comment, index) => (
      <DisplayComment
        comments={comments}
        comment={comment}
        index={index}
        key={comment.id}
        user={user}
      />
    ))
  } else {
    return (
      <h4 className={styles.commentsTitle}>{t('Be the first to comment')}</h4>
    )
  }
}

const NewComment = ({ onSubmit, onChange, currentComment }) => {
  const { t } = useTranslation()
  const newCommentClass = Boolean(currentComment)
    ? styles.newCommentSubmitActive
    : styles.newCommentSubmit
  const onKeyDown = event => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      onSubmit(event)
    }
  }
  return (
    <div>
      <form className={styles.newCommentForm} onSubmit={onSubmit}>
        <Input
          multiline
          className={styles.newCommentInput}
          rows={5}
          placeholder={t('Comment here...')}
          value={currentComment}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <button type="submit" className={newCommentClass}>
          <i className="fab fa-telegram-plane" />
        </button>
      </form>
    </div>
  )
}

const handleComment = setCurrentComment => event => {
  setCurrentComment(event.target.value)
}

const handleSubmit = ({
  setCurrentComment,
  idea,
  currentComment,
  dispatch,
}) => async event => {
  event.preventDefault()
  if (currentComment !== '') {
    dispatch(
      logsActions.add({
        action: '[IdeaCard/Comments]:ADD_COMMENT',
        iid: idea.id,
      })
    )
    dispatch(ideas.addComment({ idea, content: currentComment }))
    setCurrentComment('')
  }
}

const associateAuthorName = users => comment => {
  const author = users.find(user => user.id === comment.uid) || {}
  const authorName = author.name
  return { ...comment, authorName }
}

const normalizeComments = (comments, idea, users) => {
  const ideaComments = comments[idea.id]
  const noComments = idea.comments === 0
  const finalComments = noComments
    ? ideaComments || []
    : ideaComments || 'loading'
  return finalComments === 'loading'
    ? finalComments
    : finalComments.map(associateAuthorName(users))
}

const Comments = ({ idea, editable }) => {
  const { comments, user } = useSelector(({ users, user, ...state }) => {
    const comments = normalizeComments(state.comments, idea, users)
    return { users, user, comments }
  })
  const dispatch = useDispatch()
  const id = idea.id
  const oid = idea.oid
  useEffect(() => {
    dispatch(ideas.subscribeComments({ id, oid }))
    return () => dispatch(ideas.unsubscribeComments({ id, oid }))
  }, [dispatch, id, oid])
  const [currentComment, setCurrentComment] = useState('')
  const onChange = handleComment(setCurrentComment)
  const onSubmit = handleSubmit({
    setCurrentComment,
    idea,
    currentComment,
    dispatch,
  })
  return (
    <div>
      <div>
        <DisplayComments comments={comments} user={user} />
      </div>
      {editable ? (
        <NewComment
          onSubmit={onSubmit}
          onChange={onChange}
          currentComment={currentComment}
        />
      ) : null}
    </div>
  )
}

export default Comments
