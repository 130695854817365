import React from 'react'

import { Column } from 'components/Layout'
import styles from './Card.module.css'

const Card = ({ tag, children, pad, ...props }) => {
  const cardClass = pad ? styles.paddedCard : styles.card
  const className = [cardClass, props.className]
    .filter(t => !!t && t !== '')
    .join(' ')
  const finalTag = tag || 'div'
  const finalProps = { ...props, className }
  return (
    <Column tag={finalTag} {...finalProps}>
      {children}
    </Column>
  )
}

export default Card
