import * as auth from './actions/auth'
import * as ideas from './actions/ideas'
import * as config from './actions/config'
import * as organization from './actions/organization'
import * as user from './actions/user'

const initialState = {
  user: 'loading',
  settings: {
    emails: null,
    slack: null,
  },
  ideasToDisplay: [],
  currentIdeas: 'loading',
  archivedIdeas: 'loading',
  realizingIdeas: 'loading',
  votes: {},
  comments: {},
  users: [],
  notifications: [],
  sidebarOpened: false,
  notificationTrayOpened: false,
  organizations: 'loading',
  selectedOrganization: localStorage.getItem('selectedOrganization') || null,
  unsubscribers: [],
}

const userLogged = (state, action) => {
  const { user } = action
  return { ...state, user }
}

const putIdeasToDisplay = (state, { oid, ideas }) => {
  const { currentIdeas, user } = state
  const findCurrentIdeas = idea => {
    return currentIdeas[oid].find(i => i.id === idea.id)
  }
  const ideasToDisplay = ideas.filter(idea => {
    const found = findCurrentIdeas(idea)
    return !Boolean(found) && idea.uid !== user.uid
  })
  const userIdeasAndOldIdeas = ideas.filter(idea => {
    const found = findCurrentIdeas(idea)
    return found || idea.uid === user.uid
  })
  const newCurrentIdeas = { ...state.currentIdeas, [oid]: userIdeasAndOldIdeas }
  return { ...state, ideasToDisplay, currentIdeas: newCurrentIdeas }
}

const noCurrentIdeas = (currentIdeas, oid) => {
  const ideas = currentIdeas[oid]
  return !Boolean(ideas) || ideas.length === 0 || ideas === 'loading'
}

const updateCurrentIdeas = (state, { oid, ideas }) => {
  const curs = state.currentIdeas
  if (curs === 'loading') {
    const currentIdeas = { [oid]: ideas }
    return { ...state, currentIdeas }
  } else if (ideas === 'loading') {
    if (!Boolean(curs[oid])) {
      const currentIdeas = { ...curs, [oid]: ideas }
      return { ...state, currentIdeas }
    } else {
      return state
    }
  } else if (noCurrentIdeas(curs, oid)) {
    const currentIdeas = { ...curs, [oid]: ideas }
    return { ...state, currentIdeas }
  } else if (state.selectedOrganization !== oid) {
    const currentIdeas = { ...curs, [oid]: ideas }
    return { ...state, currentIdeas }
  } else {
    return putIdeasToDisplay(state, { oid, ideas })
  }
}

const updateArchivedIdeas = (state, { ideas, oid }) => {
  const archs = state.archivedIdeas
  if (archs === 'loading' || archs[oid] === 'loading') {
    const archivedIdeas = { [oid]: ideas }
    return { ...state, archivedIdeas }
  } else if (ideas === 'loading') {
    if (!Boolean(archs[oid])) {
      const archivedIdeas = { ...archs, [oid]: ideas }
      return { ...state, archivedIdeas }
    } else {
      return state
    }
  } else {
    const archivedIdeas = { ...archs, [oid]: ideas }
    return { ...state, archivedIdeas }
  }
}

const updateRealizingIdeas = (state, { oid, ideas }) => {
  const real = state.realizingIdeas
  if (real === 'loading' || real[oid] === 'loading') {
    const realizingIdeas = { [oid]: ideas }
    return { ...state, realizingIdeas }
  } else if (ideas === 'loading') {
    if (!Boolean(real[oid])) {
      const realizingIdeas = { ...real, [oid]: ideas }
      return { ...state, realizingIdeas }
    } else {
      return state
    }
  } else {
    const realizingIdeas = { ...real, [oid]: ideas }
    return { ...state, realizingIdeas }
  }
}

const updateIdea = (state, action) => {
  const currentIdeas = {
    ...state.currentIdeas,
    [action.idea.oid]: state.currentIdeas[action.idea.oid].map(idea => {
      if (idea.id === action.idea.id) {
        return { ...idea, ...action.idea }
      } else {
        return idea
      }
    }),
  }
  return { ...state, currentIdeas }
}

const updateVote = (state, { idea, increment, decrement }) => {
  const currentIdeas = {
    ...state.currentIdeas,
    [idea.oid]: state.currentIdeas[idea.oid].map(i => {
      if (i.id === idea.id) {
        const votes = increment ? i.votes + 1 : i.votes - 1
        const hasVoted = increment ? true : false
        return { ...i, votes, hasVoted }
      } else {
        return i
      }
    }),
  }
  return { ...state, currentIdeas }
}

const updateIdeaCommentsCount = (currentIdeas, iid, count) => {
  return currentIdeas.map(i => {
    if (i.id === iid) {
      const comments = count
      return { ...i, comments }
    } else {
      return i
    }
  })
}

const updateComments = (state, action) => {
  const { idea } = action
  const comments = { ...state.comments, [idea.id]: action.comments }
  const count = action.comments.length
  const currentIdeas = {
    ...state.currentIdeas,
    [idea.oid]: updateIdeaCommentsCount(
      state.currentIdeas[idea.oid],
      idea.id,
      count
    ),
  }
  return { ...state, comments, currentIdeas }
}

const updateUsers = (state, action) => {
  const newUserId = action.users.map(user => user.id)
  const oldUsers = state.users.filter(user => !newUserId.includes(user.id))
  const users = [...oldUsers, ...action.users]
  return { ...state, users }
}

const archiveIdea = (state, action) => {
  const currentIdeas = {
    ...state.currentIdeas,
    [action.idea.oid]: state.currentIdeas[action.idea.oid].filter(
      i => i.id !== action.idea.id
    ),
  }
  const archivedIdeas = {
    ...state.archivedIdeas,
    [action.idea.oid]: [action.idea, ...state.archivedIdeas[action.idea.oid]],
  }
  return { ...state, currentIdeas, archivedIdeas }
}

const unlogUser = (state, action) => {
  const { unsubscribers } = state
  unsubscribers.forEach(fun => fun())
  return { ...state, user: null, unsubscribers: [] }
}

const resetStore = () => ({
  ...initialState,
  user: null,
  // currentIdeas: {},
  // archivedIdeas: {},
  // realizingIdeas: {},
  // organizations: {},
})

const addUnsubscriber = (state, action) => {
  const unsubscribers = [...state.unsubscribers, action.unsubscriber]
  return { ...state, unsubscribers }
}

const removeUser = (state, { oid, uid }) => {
  const orgs = state.organizations
  const org = orgs[oid]
  const users = org.users.filter(user => user !== uid)
  const admin = org.admin.filter(user => user !== uid)
  const updatedOrg = { ...org, users, admin }
  const organizations = { ...orgs, [oid]: updatedOrg }
  return { ...state, organizations }
}

const addUser = (state, { status: stat, oid, user }) => {
  const status = stat === 'admin' ? 'admin' : 'users'
  const orgs = state.organizations
  const org = orgs[oid]
  const updatedOrg = { ...org, [status]: [user.id, ...org[status]] }
  const organizations = { ...orgs, [oid]: updatedOrg }
  return { ...state, organizations }
}

const updateOrganizations = (state, { organizations }) => {
  const isOrg = Boolean(organizations[state.selectedOrganization])
  const firstOrg = Object.values(organizations)[0] || {}
  const firstOrgId = firstOrg.id || null
  const selectedOrganization = isOrg ? state.selectedOrganization : firstOrgId
  return { ...state, organizations, selectedOrganization }
}

const updateOrganization = (state, { organization }) => {
  const oid = organization.id
  const oldOrg = state.organizations[oid] || {}
  const org = { ...oldOrg, ...organization }
  const organizations = { ...state.organizations, [oid]: org }
  const selectedOrganization =
    state.selectedOrganization !== null ? state.selectedOrganization : oid
  return { ...state, organizations, selectedOrganization }
}

const updateSelectedOrganization = (state, { value }) => {
  localStorage.setItem('selectedOrganization', value)
  const actualOid = state.selectedOrganization
  const actualOidCurrentIdeas = state.currentIdeas[actualOid]
  const currentIdeas = {
    ...state.currentIdeas,
    [actualOid]: [...state.ideasToDisplay, ...actualOidCurrentIdeas],
  }
  const ideasToDisplay = []
  return { ...state, selectedOrganization: value, currentIdeas, ideasToDisplay }
}

const updateSelectionCategories = (state, { oid, cid }) => {
  const org = state.organizations[oid]
  const selectionCategories = org.selectionCategories.includes(cid)
    ? org.selectionCategories.filter(c => c !== cid)
    : [...org.selectionCategories, cid]
  const organization = { ...org, selectionCategories }
  const organizations = { ...state.organizations, [oid]: organization }
  return { ...state, organizations }
}

const actualizeIdeas = (state, action) => {
  const ideasToDisplay = []
  const newIdeas = state.ideasToDisplay
  const oid = state.selectedOrganization
  const currentIdeas = {
    ...state.currentIdeas,
    [oid]: [...newIdeas, ...state.currentIdeas[oid]],
  }
  return { ...state, currentIdeas, ideasToDisplay }
}

const updateOrganizationSettings = (state, { oid, settings }) => {
  const organization = { ...state.organizations[oid], settings }
  const organizations = { ...state.organizations, [oid]: organization }
  return { ...state, organizations }
}

const updateUserSettings = (state, { settings }) => {
  return { ...state, settings }
}

const updateNotifications = (state, action) => {
  const newNotifications = action.notifications.filter(notif => {
    return !state.notifications.find(n => n.id === notif.id)
  })
  const notifications = [...newNotifications, ...state.notifications]
  return { ...state, notifications }
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case auth.USER_LOGGED:
      return userLogged(state, action)
    case auth.USER_NOT_LOGGED:
      return unlogUser(state, action)
    case auth.RESET_STORE:
      return resetStore()
    case auth.LOADING_USER:
      return { ...state, user: 'loading' }
    case auth.STOP_LOADING_USER:
      return { ...state, user: null }
    case ideas.UPDATE_CURRENT_IDEAS:
      return updateCurrentIdeas(state, action)
    case ideas.UPDATE_ARCHIVED_IDEAS:
      return updateArchivedIdeas(state, action)
    case ideas.UPDATE_REALIZING_IDEAS:
      return updateRealizingIdeas(state, action)
    case ideas.UPDATE_IDEA:
      return updateIdea(state, action)
    case ideas.UPDATE_COMMENTS:
      return updateComments(state, action)
    case ideas.UPDATE_USERS:
      return updateUsers(state, action)
    case ideas.UPDATE_VOTE:
      return updateVote(state, action)
    case ideas.ARCHIVE_IDEA:
      return archiveIdea(state, action)
    case ideas.REMOVE_USER:
      return removeUser(state, action)
    case ideas.ADD_USER:
      return addUser(state, action)
    case ideas.ACTUALIZE_IDEAS:
      return actualizeIdeas(state, action)
    case config.ADD_UNSUBSCRIBER:
      return addUnsubscriber(state, action)
    case config.TOGGLE_SIDEBAR:
      return { ...state, sidebarOpened: !state.sidebarOpened }
    case config.TOGGLE_NOTIFICATION_TRAY:
      return { ...state, notificationTrayOpened: !state.notificationTrayOpened }
    case config.UPDATE_NOTIFICATIONS:
      return updateNotifications(state, action)
    case organization.UPDATE_ORGANIZATIONS:
      return updateOrganizations(state, action)
    case organization.UPDATE_ORGANIZATION:
      return updateOrganization(state, action)
    case organization.UPDATE_SELECTED_ORGANIZATION:
      return updateSelectedOrganization(state, action)
    case organization.UPDATE_SELECTION_CATEGORIES:
      return updateSelectionCategories(state, action)
    case organization.UPDATE_ORGANIZATION_SETTINGS:
      return updateOrganizationSettings(state, action)
    case user.UPDATE_USER_SETTINGS:
      return updateUserSettings(state, action)
    default:
      return state
  }
}

export default rootReducer
