import React, { Fragment, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as ideas from 'reducers/actions/ideas'
import * as logsActions from 'reducers/actions/logs'
import Comments from './Comments.js'
import { Row, Column } from 'components/Layout'
import Popup from 'components/Popup'
import Card from 'components/Card'
import Spacer from 'components/Spacer'

import { shadeColor } from 'helpers/colors'
import styles from './IdeaCard.module.css'

const Content = ({ preview, idea, children }) => {
  const dispatch = useDispatch()
  const tag = preview ? Link : 'div'
  const onClick = () => {
    const options = { action: '[IdeaCard]:PREVIEW_GO_TO_IDEA', iid: idea.id }
    dispatch(logsActions.add(options))
  }
  const props = preview ? { to: `/ideas/${idea.id}`, onClick } : {}
  const className = styles.content
  const child = <p className={styles.contentText}>{children}</p>
  return React.createElement(tag, { className, ...props }, child)
}

const ArchiveButton = ({ administrable, onArchiveClick }) =>
  Boolean(administrable) && (
    <button className={styles.archiveBtn} onClick={onArchiveClick}>
      <i className="fas fa-archive" />
    </button>
  )

const RealizationButton = ({ administrable, onRealizeClick }) =>
  Boolean(administrable) && (
    <button className={styles.realizeBtn} onClick={onRealizeClick}>
      <i className="fas fa-lightbulb" />
    </button>
  )

const EditButton = ({ administrable, idea }) => {
  const dispatch = useDispatch()
  const to = `/ideas/${idea.id}/edit`
  const onClick = () => {
    const options = { action: '[IdeaCard]:GO_TO_EDIT', iid: idea.id }
    dispatch(logsActions.add(options))
  }
  return (
    Boolean(administrable) && (
      <Link className={styles.btn} onClick={onClick} to={to}>
        <i className="fas fa-edit" />
      </Link>
    )
  )
}

const VotesButton = ({ editable, idea }) => {
  const dispatch = useDispatch()
  const tag = editable ? 'button' : 'div'
  const className = editable ? styles.btn : styles.icon
  const onEdit = () => {
    const options = { action: '[IdeaCard]:VOTE_IDEA', iid: idea.id }
    dispatch(logsActions.add(options))
    return dispatch(ideas.vote(idea))
  }
  const onClick = editable ? onEdit : null
  const props = { className, onClick }
  const voteIcon = idea.hasVoted ? 'fas fa-thumbs-up' : 'far fa-thumbs-up'
  return React.createElement(tag, props, <i className={voteIcon} />)
}

const VotesSection = props => (
  <Fragment>
    <VotesButton {...props} />
    <span className={styles.count}>{props.idea.votes}</span>
  </Fragment>
)

const CommentsSection = ({ idea, onClick, preview }) => {
  const dispatch = useDispatch()
  const icon = <i className="far fa-comment" />
  const tag = preview ? Link : 'button'
  const iid = idea.id
  const to = `/ideas/${iid}?comments=true`
  const onPreviewClick = () => {
    const options = { action: '[IdeaCard]:PREVIEW_GO_TO_COMMENTS', iid }
    dispatch(logsActions.add(options))
  }
  const props = preview ? { to, onClick: onPreviewClick } : { onClick }
  return (
    <Fragment>
      {React.createElement(tag, { className: styles.icon, ...props }, icon)}
      <span className={styles.count}>{idea.comments}</span>
    </Fragment>
  )
}

const SeeMore = ({ preview, idea, color }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const backgroundImage = computeGradient(color)
  const iid = idea.id
  const to = `/ideas/${iid}`
  const onClick = () => {
    const options = { action: '[IdeaCard]:PREVIEW_SEE_MORE', iid }
    dispatch(logsActions.add(options))
  }
  return (
    Boolean(preview) && (
      <Link
        to={to}
        className={styles.seeMore}
        style={{ backgroundImage }}
        onClick={onClick}
      >
        {t('See more')}
      </Link>
    )
  )
}

const Interactions = props => {
  const { t } = useTranslation()
  const { idea, editable, user, preview } = props
  const isAuthor = user.uid === idea.uid
  const isAdmin = (user.claims || {})[idea.oid] === 'admin'
  const hasRights = isAuthor || isAdmin
  const administrable = hasRights && editable
  const date = idea.createdAt.toDate()
  return (
    <div className={styles.interactions}>
      <Row justify="space-between" align="baseline">
        <div className={styles.date}>{t('ideaDate', { date })}</div>
        <SeeMore {...props} />
      </Row>
      <Row justify="end" align="baseline">
        <ArchiveButton
          onArchiveClick={props.onArchiveClick}
          administrable={editable && isAdmin}
        />
        <RealizationButton
          onRealizeClick={props.onRealizeClick}
          administrable={editable && isAdmin}
        />
        <EditButton idea={idea} administrable={administrable} />
        <div className="grow" />
        <VotesSection editable={editable} idea={idea} />
        <CommentsSection
          idea={idea}
          onClick={props.onCommentClick}
          preview={preview}
        />
      </Row>
    </div>
  )
}

const ArchivePopup = ({ open, onClose, idea }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onAccept = () => {
    const options = { action: '[IdeaCard]:ARCHIVE_IDEA', iid: idea.id }
    dispatch(logsActions.add(options))
    return dispatch(ideas.archiveIdea(idea))
  }
  return (
    <Popup open={open} onClose={onClose} onAccept={onAccept}>
      <div>
        <div style={{ padding: '0 6px 6px 0', fontWeight: 600 }}>
          {t('Archive idea?')}
        </div>
        <div>{idea.title}</div>
      </div>
    </Popup>
  )
}

const RealizePopup = ({ open, onClose, idea }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onAccept = () => {
    const options = { action: '[IdeaCard]:REALIZE_IDEA', iid: idea.id }
    dispatch(logsActions.add(options))
    return dispatch(ideas.realizeIdea(idea))
  }
  return (
    <Popup open={open} onClose={onClose} onAccept={onAccept}>
      <div>
        <div style={{ padding: '0 6px 6px 0', fontWeight: 600 }}>
          {t('Realize idea?')}
        </div>
        <div>{idea.title}</div>
      </div>
    </Popup>
  )
}

const CloseCard = ({ close }) =>
  Boolean(close) && (
    <Fragment>
      <Spacer size={6} />
      <i
        className="fas fa-times"
        style={{ fontSize: '1.5rem' }}
        onClick={close}
      />
    </Fragment>
  )

const Title = ({ idea, close }) => (
  <Fragment>
    <Spacer size={6} />
    <Row className={styles.borderBottom} justify="space-between" align="center">
      <h3 className={styles.title}>{idea.title}</h3>
      <CloseCard close={close} />
    </Row>
  </Fragment>
)

const CommentsDroppable = ({ idea, editable, dropped, setDropped }) => {
  const dispatch = useDispatch()
  const arrowIcon = dropped ? 'fas fa-angle-up' : 'fas fa-angle-down'
  const onCommentDroppableClick = () => {
    const action = dropped
      ? '[IdeaCard]:HIDE_COMMENTS_ARROW'
      : '[IdeaCard]:SHOW_COMMENTS_ARROW'
    const options = { action, iid: idea.id }
    dispatch(logsActions.add(options))
    setDropped(!dropped)
  }
  return (
    <div className={styles.droppable}>
      {Boolean(dropped) && (
        <div className={styles.dropped}>
          <Comments idea={idea} editable={editable} />
        </div>
      )}
      <button className={styles.dropBtn} onClick={onCommentDroppableClick}>
        <i className={arrowIcon} />
      </button>
    </div>
  )
}

const computeGradient = color => {
  const lighten = shadeColor(color || '#ffffff', 40)
  const background = `linear-gradient(to right, ${color}, ${lighten})`
  return background
}

const ColorStripe = ({ color }) => {
  const background = computeGradient(color)
  return <div className={styles.sideStripe} style={{ background }} />
}

const Body = ({ children }) => (
  <Column className={styles.body} grow>
    {children}
  </Column>
)

const RenderContent = ({ idea, preview }) => {
  if (idea.content) {
    return (
      <Content idea={idea} preview={preview}>
        {preview && idea.content.length > 300
          ? idea.content.slice(0, 300) + '…'
          : idea.content}
      </Content>
    )
  } else {
    return <div style={{ marginTop: '0.75rem' }} />
  }
}

const IdeaCard = ({
  idea,
  categories,
  organization,
  editable,
  preview,
  close,
  width,
}) => {
  const user = useSelector(({ user }) => user)
  const [redirect, setRedirect] = useState(false)
  const [popup, setPopup] = useState(false)
  const [realizePopup, setRealizePopup] = useState(false)
  const [dropped, setDropped] = useState(true)
  const dispatch = useDispatch()
  if (redirect) {
    setRedirect(false)
    return <Redirect to="/ideas/all" />
  } else if (idea) {
    const category = categories.find(cat => cat.id === idea.category) || {}
    const onArchiveClick = () => {
      const options = { action: '[IdeaCard]:OPEN_POPUP_ARCHIVE', iid: idea.id }
      dispatch(logsActions.add(options))
      setPopup(true)
    }
    const onRealizeClick = () => {
      const options = { action: '[IdeaCard]:REALIZE_ACTION', iid: idea.id }
      dispatch(logsActions.add(options))
      setRealizePopup(true)
    }
    const onCommentClick = () => {
      const action = dropped
        ? '[IdeaCard]:HIDE_COMMENTS_BUTTON'
        : '[IdeaCard]:SHOW_COMMENTS_BUTTON'
      const options = { action, iid: idea.id }
      dispatch(logsActions.add(options))
      setDropped(!dropped)
    }
    return (
      <Fragment>
        <ArchivePopup
          open={popup}
          onClose={() => setPopup(false)}
          idea={idea}
        />
        <RealizePopup
          open={realizePopup}
          onClose={() => setRealizePopup(false)}
          idea={idea}
        />
        <Card style={{ width }}>
          <ColorStripe color={category.color} />
          <Body>
            <Title idea={idea} close={close} />
            <RenderContent idea={idea} preview={preview} />
            <Interactions
              user={user}
              idea={idea}
              editable={editable}
              color={category.color}
              preview={preview}
              onArchiveClick={onArchiveClick}
              onCommentClick={onCommentClick}
              onRealizeClick={onRealizeClick}
            />
            {!Boolean(preview) && (
              <CommentsDroppable
                idea={idea}
                editable={editable}
                dropped={dropped}
                setDropped={setDropped}
              />
            )}
            <Spacer size={3} />
          </Body>
        </Card>
      </Fragment>
    )
  } else {
    return null
  }
}

export default IdeaCard
export { Title, Content, ColorStripe, IdeaCard, Body, Interactions }
