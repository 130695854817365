import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Link /*, useLocation */ } from 'react-router-dom'
// import firebase from 'firebase'

import { config } from 'reducers/selectors'
import * as configActions from 'reducers/actions/config'
// import { DateDiff } from 'helpers/date'

import ProfilePicture from 'components/ProfilePicture'
import { Row /*, Column */ } from 'components/Layout'
// import NotificationTray from './NotificationTray'
import ProfilePopUp from './ProfilePopUp'
import styles from './Navbar.module.css'

const generateClassName = ({ hiddenPhone, className }) => {
  return [hiddenPhone ? 'hidden-phone' : '', className]
    .filter(s => !!s && s !== '')
    .join(' ')
}

const Connect = ({ signIn, ...props }) => {
  const { t } = useTranslation()
  const className = generateClassName(props)
  return (
    <Link
      to="/authentication"
      className={className}
      style={{ color: 'inherit' }}
    >
      {t('Login')}
    </Link>
  )
}

const RenderProfileOrConnect = ({ isConnected, user, ...props }) => {
  const dispatch = useDispatch()
  const { notificationTrayOpened } = useSelector(config.traysOpened)
  const onClick = () => {
    if (notificationTrayOpened) {
      dispatch(configActions.toggleNotificationTray)
    }
    dispatch(configActions.toggleSidebar)
  }
  if (isConnected) {
    return (
      <Fragment>
        {/* <NotificationTray /> */}
        <ProfilePicture
          onClick={onClick}
          url={user.profilePicture}
          {...props}
        />
      </Fragment>
    )
  } else {
    return <Connect {...props} />
  }
}

// const periodicityToMillis = periodicity => {
//   const daysToMillis = days => days * 24 * 60 * 60 * 1000
//   switch (periodicity) {
//     case '1w':
//       return daysToMillis(7)
//     case '2w':
//       return daysToMillis(14)
//     case '3w':
//       return daysToMillis(21)
//     case '1m':
//       return daysToMillis(30)
//     case '2m':
//       return daysToMillis(60)
//     default:
//       return daysToMillis(30)
//   }
// }
//
// const computeNextSelection = org => {
//   if (org) {
//     const { lastSelection, periodicity } = org
//     const last = lastSelection.toDate()
//     const now = new Date()
//     last.setTime(last.getTime() + periodicityToMillis(periodicity))
//     return DateDiff.inDays(now, last)
//   } else {
//     return null
//   }
// }
//
// const useIsOnIdeas = () => {
//   const { pathname } = useLocation()
//   const isOnAll = pathname === '/ideas/all'
//   const isOnIdea =
//     pathname.split('/').length === 3 && pathname.startsWith('/ideas')
//   return isOnAll || isOnIdea
// }

const Navbar = props => {
  // const { t } = useTranslation()
  const { isConnected, user, sidebarOpened /* organization */ } = useSelector(
    ({ user, sidebarOpened, selectedOrganization, organizations }) => {
      const isConnected = !!user && user !== 'loading'
      const organization =
        organizations === 'loading' ? null : organizations[selectedOrganization]
      return { isConnected, user, sidebarOpened, organization }
    }
  )
  const logoClass = styles.logo
  const logoLink = isConnected ? '/ideas/all' : '/'
  // const nextElection = computeNextSelection(organization)
  // const isOnIdeas = useIsOnIdeas()
  return (
    <header className={styles.mainNav}>
      <Row grow align="center">
        <Link className={logoClass} to={logoLink}>
          <img src="/icon.png" alt="icon" style={{ height: '40px' }} />
          <span className="hidden-phone" style={{ maxWidth: 100 }}>
            Kaizen Board
          </span>
        </Link>
      </Row>
      {/* {nextElection !== null && isOnIdeas ? (
        <Column
          grow
          justify="center"
          align="center"
          className={styles.electionSection}
        >
          <div className={styles.electionTitle}>{t('Next election')}</div>
          <span className={styles.electionCount}>
            {nextElection === 0
              ? t('Today!')
              : nextElection === 1
              ? t('Tomorrow!')
              : t('electionDays', { count: nextElection })}
          </span>
        </Column>
      ) : null} */}
      {/* {process.env.NODE_ENV === 'development' ? (
        <button
          className="btn active"
          onClick={() => firebase.functions().httpsCallable('electIdea')()}
        >
          Elect
        </button>
      ) : null} */}
      <Row grow align="center" justify="end">
        <RenderProfileOrConnect
          isConnected={isConnected}
          user={user}
          sidebarOpened={sidebarOpened}
          {...props}
        />
        <ProfilePopUp {...props} />
      </Row>
    </header>
  )
}

export default Navbar
