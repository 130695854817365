import React from 'react'

const ComboChart = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={size || 48}
    height={size || 48}
  >
    <linearGradient
      id="VSmTMqV81igHcIY77oRNXa"
      x1="7.758"
      x2="40.53"
      y1="11.124"
      y2="11.124"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#912fbd" />
      <stop offset="1" stopColor="#9332bf" />
    </linearGradient>
    <polygon
      fill="url(#VSmTMqV81igHcIY77oRNXa)"
      points="16.265,17.097 7.758,14.97 8.242,13.03 15.735,14.903 23.735,7.903 31.83,9.927 39.47,5.152 40.53,6.848 32.17,12.073 24.265,10.097"
    />
    <path
      fill="#50e6ff"
      d="M44,19v22h-8V19c0-0.552,0.448-1,1-1h6C43.552,18,44,18.448,44,19z"
    />
    <path fill="#35c1f1" d="M36,26v15h-8V26H36z" />
    <path
      fill="#199be2"
      d="M28,24v17h-8V24c0-0.552,0.448-1,1-1h6C27.552,23,28,23.448,28,24z"
    />
    <path fill="#0078d4" d="M20,31v10h-8V31H20z" />
    <path
      fill="#0d62ab"
      d="M12,28v13H4V28c0-0.552,0.448-1,1-1h6C11.552,27,12,27.448,12,28z"
    />
    <linearGradient
      id="VSmTMqV81igHcIY77oRNXb"
      x1="38"
      x2="42"
      y1="6"
      y2="6"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#c965eb" />
      <stop offset="1" stopColor="#c767e5" />
    </linearGradient>
    <circle cx="40" cy="6" r="2" fill="url(#VSmTMqV81igHcIY77oRNXb)" />
    <linearGradient
      id="VSmTMqV81igHcIY77oRNXc"
      x1="30"
      x2="34"
      y1="11"
      y2="11"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#c965eb" />
      <stop offset="1" stopColor="#c767e5" />
    </linearGradient>
    <circle cx="32" cy="11" r="2" fill="url(#VSmTMqV81igHcIY77oRNXc)" />
    <linearGradient
      id="VSmTMqV81igHcIY77oRNXd"
      x1="22"
      x2="26"
      y1="9"
      y2="9"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#c965eb" />
      <stop offset="1" stopColor="#c767e5" />
    </linearGradient>
    <circle cx="24" cy="9" r="2" fill="url(#VSmTMqV81igHcIY77oRNXd)" />
    <linearGradient
      id="VSmTMqV81igHcIY77oRNXe"
      x1="14"
      x2="18"
      y1="16"
      y2="16"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#c965eb" />
      <stop offset="1" stopColor="#c767e5" />
    </linearGradient>
    <circle cx="16" cy="16" r="2" fill="url(#VSmTMqV81igHcIY77oRNXe)" />
    <linearGradient
      id="VSmTMqV81igHcIY77oRNXf"
      x1="6"
      x2="10"
      y1="14"
      y2="14"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#c965eb" />
      <stop offset="1" stopColor="#c767e5" />
    </linearGradient>
    <circle cx="8" cy="14" r="2" fill="url(#VSmTMqV81igHcIY77oRNXf)" />
  </svg>
)

export default ComboChart
