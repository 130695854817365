import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'components/Layout'
import Spacer from 'components/Spacer'

import styles from './Toast.module.css'

const TimeoutStripe = React.forwardRef(({ active, timeout }, ref) => {
  if (timeout) {
    return (
      <Row>
        <div
          ref={ref}
          className={styles.timeoutStripe}
          style={{
            flexBasis: active ? '0%' : null,
            borderTopRightRadius: active ? 0 : null,
            transition: `flex-basis ${timeout}ms linear`,
          }}
        />
        <div className={styles.rightTimeoutStripe} />
      </Row>
    )
  } else {
    return null
  }
})

const Toast = ({ close, timeout, children, onClose }) => {
  const [active, setActive] = useState(false)
  const [opened, setOpened] = useState(false)
  const [canceller, setCanceller] = useState(null)
  const { t } = useTranslation()
  const closeToast = useCallback(() => {
    setTimeout(() => {
      if (onClose instanceof Function) onClose()
    }, 1500)
    clearTimeout(canceller)
    setOpened(false)
  }, [canceller, onClose])
  const triggerActivity = node => {
    if (timeout) {
      setTimeout(() => setActive(true), 2000)
      setCanceller(
        setTimeout(() => {
          setOpened(false)
          setTimeout(() => {
            if (onClose instanceof Function) onClose()
          }, 1500)
        }, timeout + 2000)
      )
    }
  }
  useEffect(() => {
    if (close) {
      closeToast()
    } else {
      setTimeout(() => setOpened(true), 1000)
    }
  }, [closeToast, close])
  const ref = useCallback(triggerActivity, [timeout])
  return (
    <Row className={opened ? styles.openedToast : styles.closedToast}>
      <div className={styles.content}>
        <TimeoutStripe ref={ref} active={active} timeout={timeout} />
        {!timeout ? (
          children
        ) : (
          <Row className="pad-m" align="center" justify="space-between">
            {children}
            <Spacer size={6} />
            <button className="btn active" onClick={closeToast}>
              {t('Close')}
            </button>
          </Row>
        )}
      </div>
    </Row>
  )
}

export default Toast
