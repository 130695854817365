import React from 'react'

import styles from './Input.module.css'

const Select = ({ options, selected, onChange, label }) => {
  const content = (
    <select className={styles.select} value={selected} onChange={onChange}>
      {options.map(opt => (
        <option className={styles.option} value={opt.id} key={opt.id}>
          {opt.name}
        </option>
      ))}
    </select>
  )
  if (label) {
    return (
      <label>
        <span style={{ paddingRight: '6px' }}>{label}</span>
        {content}
      </label>
    )
  } else {
    return content
  }
}

export default Select
