import React from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'components/Layout'
import Spacer from 'components/Spacer'
import Card from 'components/Card'
import styles from './Popup.module.css'

const Popup = ({ open, onClose, onAccept, children }) => {
  const { t } = useTranslation()
  const accept = async () => {
    onClose()
    onAccept()
  }
  if (open) {
    return (
      <Column align="center" justify="center" className={styles.popup}>
        <Card pad>
          {children}
          <Spacer size={12} />
          <Row>
            <button
              style={{ flex: 1, marginRight: '12px' }}
              className="btn active"
              onClick={onClose}
            >
              {t('No')}
            </button>
            <button
              style={{ flex: 1, marginLeft: '12px' }}
              className="btn action"
              onClick={accept}
            >
              {t('Yes')}
            </button>
          </Row>
        </Card>
      </Column>
    )
  } else {
    return null
  }
}

export default Popup
