import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import * as ideas from 'reducers/actions/ideas'
import * as logsActions from 'reducers/actions/logs'

import Input from 'components/Input/Text'
import Submit from 'components/Input/Submit'
import Select from 'components/Input/Select'
import { Row } from 'components/Layout'
import NotLoggedIn from 'components/NotLoggedIn'
import styles from './Add.module.css'
import Card from 'components/Card'
import Spacer from 'components/Spacer'

const handleChange = setter => event => setter(event.target.value)

const handleSubmit = ({
  dispatch,
  title,
  content,
  category,
  setRedirect,
  organization,
}) => async event => {
  event.preventDefault()
  if (title !== '') {
    const idea = { title, content, category }
    dispatch(
      logsActions.add({ action: '[Add]:NEW_IDEA', idea, oid: organization.id })
    )
    await dispatch(ideas.addNewIdea({ idea, oid: organization.id }))
    setRedirect(true)
  }
}

const onCmdClick = onSubmit => event => {
  if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
    onSubmit(event)
  }
}

const NewIdea = ({
  onSubmit,
  title,
  content,
  setTitle,
  setContent,
  category,
  setCategory,
  organization,
  categories,
}) => {
  const { t } = useTranslation()
  const canSend = title !== ''
  return (
    <form onSubmit={onSubmit}>
      <Card pad className="add-idea-form">
        <Input
          label={t('Title')}
          labelBold
          bold
          value={title}
          onChange={handleChange(setTitle)}
          placeholder={t('My Awesome Idea')}
          onKeyDown={onCmdClick(onSubmit)}
        />
        <Input
          label={t('Content')}
          labelBold
          onChange={handleChange(setContent)}
          placeholder={t('How about...')}
          rows={5}
          value={content}
          multiline
          resizable
          onKeyDown={onCmdClick(onSubmit)}
        />
        <Spacer size={6} />
        <Row align="center" justify="center">
          <Select
            options={categories.map(cat => {
              if (cat.name === 'To categorize') {
                return { ...cat, name: t(cat.name) }
              } else {
                return cat
              }
            })}
            selected={category}
            onChange={handleChange(setCategory)}
            label={t('Categories')}
          />
        </Row>
      </Card>
      <Submit
        disabled={!canSend}
        style={{ margin: '1rem 0', textTransform: 'uppercase' }}
        value={t('Submit')}
      />
    </form>
  )
}

const Add = () => {
  const { t } = useTranslation()
  const { user, organization, categories } = useSelector(
    ({ user, organizations, selectedOrganization }) => {
      const organization =
        (Object.values(organizations) || []).find(
          org => org.id === selectedOrganization
        ) || {}
      const categories = organization.categories || []
      return { user, organization, categories }
    }
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [redirect, setRedirect] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [category, setCategory] = useState(
    categories.find(cat => cat.id === location.state) ? location.state : ''
  )
  const onSubmit = handleSubmit({
    dispatch,
    title,
    content,
    category: category !== '' ? category : (categories[0] || {}).id || null,
    setRedirect,
    organization,
  })
  if (user) {
    return (
      <div className="add">
        <div className={styles.wrapper}>
          <Row
            tag="button"
            align="center"
            className={styles.returnButton}
            onClick={() => {
              dispatch(
                logsActions.add({
                  type: '[Add]:NEW_IDEA_GO_BACK',
                  oid: organization.id,
                })
              )
              history.goBack()
            }}
          >
            <i className="fas fa-long-arrow-alt-left" />
            {t('Back to ideas list')}
          </Row>
          <h1>{t('So you have a great idea ?')}</h1>
          <h2>{t('Let’s add it to the list!')}</h2>
          {redirect ? <Redirect to="/ideas/all" /> : null}
          <NewIdea
            onSubmit={onSubmit}
            title={title}
            content={content}
            setTitle={setTitle}
            setContent={setContent}
            category={category}
            setCategory={setCategory}
            organization={organization}
            categories={categories}
          />
        </div>
      </div>
    )
  } else {
    return <NotLoggedIn />
  }
}

export default Add
