import React, { useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Title from 'components/Title'
import { Column, Row } from 'components/Layout'
import ProfilePicture from 'components/ProfilePicture'
import Spacer from 'components/Spacer'
import Loader from 'components/Loader'
import Callout from 'components/Callout'
import NotLoggedIn from 'components/NotLoggedIn'
import Card from 'components/Card'
import Checkbox from 'components/Input/Checkbox'

import * as auth from 'reducers/actions/auth'
import * as userActions from 'reducers/actions/user'
import * as logsActions from 'reducers/actions/logs'

import styles from './Profile.module.css'

const InputProfilePicture = ({ setLoader }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const inputChange = async event => {
    dispatch(logsActions.add({ action: '[Profile]:UPDATE_PROFILE_PICTURE' }))
    setLoader(true)
    const [picture] = event.target.files
    await dispatch(auth.updateProfilePicture(picture))
    setLoader(false)
  }
  return (
    <label className="btn action">
      <input
        className={styles.hiddenInput}
        type="file"
        onChange={inputChange}
      />
      {t('Change your profile picture')}
    </label>
  )
}

const RenderProfilePicture = ({ loader, user }) => {
  if (loader) {
    return (
      <Row className={styles.loaderWrapper} align="center" justify="center">
        <Loader />
      </Row>
    )
  } else {
    return <ProfilePicture huge url={user.profilePicture} />
  }
}

const ProfileCard = ({ user }) => {
  const [loader, setLoader] = useState(false)
  const { t } = useTranslation()
  return (
    <Fragment>
      <Title>{t('Profile')}</Title>
      <Spacer size={6} />
      <Card pad>
        <Column align="center">
          <RenderProfilePicture loader={loader} user={user} />
          <Spacer size={6} />
          <InputProfilePicture setLoader={setLoader} />
          <Spacer size={6} />
          <div style={{ maxWidth: 300 }}>
            <Callout info>{t('When changing your profile picture')}</Callout>
          </div>
          <Spacer size={12} />
          <div className={styles.displayName}>{user.displayName}</div>
          <Spacer size={3} />
          <div className={styles.email}>{user.email}</div>
        </Column>
      </Card>
    </Fragment>
  )
}

const ProfileSettings = ({ user, settings }) => {
  const { t } = useTranslation()
  const slackSettings = settings.slack || {}
  const dispatch = useDispatch()
  return (
    <Fragment>
      <Title ellipsis>{t('Notifications')}</Title>
      <Spacer size={6} />
      <Card pad>
        <div style={{ fontWeight: 600 }}>{t('Slack')}</div>
        <Spacer size={6} />
        <Row align="center">
          <Checkbox
            small
            checked={slackSettings.ideasCreated}
            style={{ paddingRight: 12 }}
            onClick={() => {
              dispatch(
                logsActions.add({
                  action: '[Profile]:UPDATE_SLACK_SETTINGS',
                  ideasCreated: true,
                })
              )
              const payload = { ideasCreated: true }
              dispatch(userActions.updateSlackSettings(payload))
            }}
          />
          <div>{t('Receive responses to ideas I posted')}</div>
        </Row>
        <Spacer size={6} />
        <Row align="center">
          <Checkbox
            small
            checked={slackSettings.ideasParticipation}
            style={{ paddingRight: 12 }}
            onClick={() => {
              dispatch(
                logsActions.add({
                  action: '[Profile]:UPDATE_SLACK_SETTINGS',
                  ideasParticipation: true,
                })
              )
              const payload = { ideasParticipation: true }
              dispatch(userActions.updateSlackSettings(payload))
            }}
          />
          <div>{t('Receive responses to ideas where I participated')}</div>
        </Row>
      </Card>
    </Fragment>
  )
}

const RenderNoOrgs = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <Fragment>
      <Callout>{t('You’re not part of an organization.')}</Callout>
      <Spacer size={12} />
      <Column align="start">
        {t('Maybe you want to create an organization?')}
        <Spacer size={6} />
        <Link
          onClick={() => {
            dispatch(
              logsActions.add({
                action: '[Profile]:CREATE_ORGANIZATION_NO_ORGS',
              })
            )
          }}
          to="/organization/new"
          className="btn active"
        >
          {t('Let’s go!')}
        </Link>
      </Column>
    </Fragment>
  )
}

const RenderOrgs = ({ user, organizations }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectLabel = id => {
    return user.claims[id] === 'admin'
      ? t('You’re admin of the group')
      : t('You’re member of the group')
  }
  if (organizations.length === 0) {
    return <RenderNoOrgs />
  } else {
    return (
      <Fragment>
        <div style={{ fontWeight: 600 }}>{t('Create new organization')}</div>
        <Spacer size={6} />
        <Callout>{t('You can create more boards.')}</Callout>
        <Spacer size={6} />
        <Link
          onClick={() => {
            dispatch(
              logsActions.add({
                action: '[Profile]:CREATE_ORGANIZATION_WITH_ORGS',
              })
            )
          }}
          to="/organization/new"
          className="btn active"
        >
          {t('Create new board')}
        </Link>
        <Spacer size={12} />
        <div style={{ fontWeight: 600 }}>{t('See your actual boards')}</div>
        <Spacer size={6} />
        {organizations.map(({ id, name }) => (
          <Row key={id} align="center" className={styles.organizationRow}>
            <span style={{ flex: 1 }}>{selectLabel(id)}</span>
            <Spacer size={3} />
            <Link
              className={styles.orgPage}
              onClick={() => {
                dispatch(
                  logsActions.add({
                    action: '[Profile]:GO_TO_ORGANIZATION_SETTINGS',
                    oid: id,
                  })
                )
              }}
              to={`/organization/${id}`}
              style={{ textAlign: 'center' }}
            >
              {name}
            </Link>
          </Row>
        ))}
      </Fragment>
    )
  }
}

const OrganizationCard = ({ user, organizations }) => {
  const { t } = useTranslation()
  const orgs = organizations === 'loading' ? [] : Object.values(organizations)
  return (
    <Fragment>
      <Title ellipsis>{t('Organizations')}</Title>
      <Spacer size={6} />
      <Card pad>
        <RenderOrgs user={user} organizations={orgs} />
      </Card>
    </Fragment>
  )
}

const Profile = () => {
  const { user, settings, organizations } = useSelector(
    ({ user, organizations, settings }) => {
      return { user, settings, organizations }
    }
  )
  if (!!user && user !== 'loading') {
    return (
      <Column align="center">
        <div className={styles.wrapper}>
          <ProfileCard user={user} />
          <Spacer size={24} />
          <ProfileSettings user={user} settings={settings} />
          <Spacer size={24} />
          <OrganizationCard user={user} organizations={organizations} />
        </div>
      </Column>
    )
  } else {
    return <NotLoggedIn />
  }
}
export default Profile
